<ng-container *ngIf="!isRegistered">
    <div class="card-body justify-content-center p-4">
        <ng-container *ngIf="isEnterpriseRoute">
            <h1>Sign up for enterprise idea protection</h1>
        </ng-container>
        <ng-container *ngIf="!isEnterpriseRoute">
            <h1>Where should we send your Protected Idea?</h1>
        </ng-container>
        <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
        <form id="sign-up-form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mb-3">
                <label class="form-label" for="email">Email</label>
                <input
                    class="form-control form-control-lg"
                    id="email"
                    type="text"
                    formControlName="email"
                    [ngClass]="{
                        'is-invalid': form.controls.email.touched && form.controls.email.errors
                    }"
                />
                <div class="invalid-feedback" *ngIf="form.controls.email.hasError('required')">Required field</div>
                <div class="invalid-feedback" *ngIf="form.controls.email.hasError('email')">
                    Incorrect email address
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label" for="password">Password</label>
                <input
                    type="text"
                    class="form-control form-control-lg"
                    type="password"
                    id="password"
                    formControlName="password"
                    [ngClass]="{
                        'is-invalid': form.controls.password.touched && form.controls.password.errors
                    }"
                    minlength="8"
                />
                <div class="invalid-feedback" *ngIf="form.controls.password.hasError('required')">Required field</div>
                <div class="invalid-feedback" *ngIf="form.controls.password.hasError('minlength')">
                    Password must have at least 8 characters
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label" for="password">Your Full Name</label>
                <input
                    type="text"
                    class="form-control form-control-lg"
                    type="text"
                    id="fullName"
                    formControlName="fullName"
                    [ngClass]="{
                        'is-invalid': form.controls.fullName.touched && form.controls.fullName.errors
                    }"
                    minlength="2"
                />
                <div class="invalid-feedback" *ngIf="form.controls.fullName.hasError('required')">Required field</div>
                <div class="invalid-feedback" *ngIf="form.controls.fullName.hasError('minlength')">
                    Password must have at least 2 characters
                </div>
            </div>
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="terms"
                    formControlName="termsAccepted"
                    [ngClass]="{
                        'is-invalid': form.controls.termsAccepted.touched && form.controls.termsAccepted.errors
                    }"
                    (change)="toggleTerms()"
                />
                <label class="form-check-label" for="terms">
                    I accept
                    <a class="terms" [routerLink]="['/terms']" target="_blank">terms of submission</a>
                </label>
                <div class="invalid-feedback" *ngIf="form.controls.termsAccepted.hasError('required')">
                    You must agree before submitting
                </div>
            </div>
            <button class="btn btn-primary w-100 mt-5 mb-2" type="submit" [disabled]="isLoading || !this.form.valid">
                <span *ngIf="isLoading" class="spinner-border">
                    <span class="visually-hidden">Loading...</span>
                </span>
                <span *ngIf="!isLoading">Submit</span>
            </button>
        </form>
    </div>
    <div class="card-footer pb-5 pt-2 text-center">
        <p>Already have an account?</p>
        <a [routerLink]="['/sign-in']" [queryParams]="{ returnUrl: '/upload-idea' }">Sign In</a>
    </div>
</ng-container>
<ng-container *ngIf="isRegistered">
    <div class="p-5 pt-0">
        <h1 class="my-5">Thank you for signing up</h1>
        <p>
            Because we take protecting your ideas seriously, we want to make sure we have your correct email address.
            Please check your email inbox for a verification message. Follow the instructions in the email to verify
            your address and complete the sign-up process. If you don’t see the email, make sure to check your spam or
            junk folder.
        </p>
        <p>
            If you need any help,
            <a href="https://www.instantip.today/contact/" target="_blank">please contact our support team</a> for
            assistance.
        </p>
    </div>
</ng-container>
