import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { environment } from '@/environments/environment';

@Component({
    selector: 'app-terms',
    standalone: true,
    imports: [RouterLink, NgIf],
    templateUrl: './terms.component.html'
})
export class TermsComponent {
    appName: string = environment.appName;
}
