import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
    providedIn: 'root',
})
export class PdfGeneratorService {
    constructor() {}

    public generatePdf(htmlElementId: string, pdfFileName: string): void {
        const data = document.getElementById(htmlElementId);
        if (data) {
            html2canvas(data)
                .then((canvas) => {
                    const imgWidth = 208;
                    const pageHeight = 295;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;

                    const contentDataURL = canvas.toDataURL('image/png');
                    const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF

                    let position = 0;
                    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;

                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        pdf.addPage();
                        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }

                    pdf.save(pdfFileName);
                })
                .catch((error) => {
                    console.error('Error generating PDF:', error);
                });
        } else {
            console.error('Element not found:', htmlElementId);
        }
    }
}
