import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { UserService } from '@/app/services';
import { Subject, takeUntil, iif, of, take } from 'rxjs';
import { User } from '@/app/models';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IdeasListComponent } from '@/app/components/ideas-list/ideas-list.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UploadService } from '@/app/services/upload.service';
import { switchMap } from 'rxjs/operators';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditProfileCoverPicturesComponent } from '../edit-profile-cover-pictures/edit-profile-cover-pictures.component';

@Component({
    selector: 'app-user-settings',
    standalone: true,
    imports: [NgOptimizedImage, ReactiveFormsModule, CommonModule, IdeasListComponent, RouterLink, MatExpansionModule],
    templateUrl: './user-settings.component.html',
    styleUrl: './user-settings.component.scss',
})
export class UserSettingsComponent {
    @ViewChild('fileUploadRef') fileUploadRef: ElementRef | null = null;
    private ngUnsubscribe = new Subject<void>();
    public isLoading = false;
    public user: User | null = null;
    public error = '';
    public form = new FormGroup({
        privateVault: new FormControl('', { nonNullable: false, validators: [] }),
        fullName: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
        description: new FormControl('', { nonNullable: false, validators: [] }),
        profileImage: new FormControl<File | null>(null, { nonNullable: true, validators: [] }),
        coverImage: new FormControl<File | null>(null, { nonNullable: true, validators: [] }),
        tokenTracker: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
        logo: new FormControl<File | null>(null, { nonNullable: true, validators: [] }),
    });
    public dialogRef: MatDialogRef<EditProfileCoverPicturesComponent> | null = null;

    profileImageUrl = '';
    coverImageUrl = '';
    logoImageUrl = '';
    logoPreview: string | ArrayBuffer | null = null;
    profilePreview: string | ArrayBuffer | null = null;
    coverPreview: string | ArrayBuffer | null = null;
    currentImageUpload?: 'cover' | 'profile' | 'logo';

    constructor(
        private userService: UserService,
        private uploadService: UploadService,
        private dialog: MatDialog,
        private router: Router,
    ) {}

    ngOnInit() {
        this.form.controls.tokenTracker.disable();
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
            if (user) {
                this.form.patchValue({
                    privateVault: user.privateVault,
                    fullName: user.fullName,
                    description: user.description,
                    tokenTracker: user.tokenTracker || undefined,
                });
                this.profileImageUrl = user.profileImage?.url
                    ? user.profileImage?.url + '?ts=' + new Date().getTime()
                    : '/assets/profile_default.jpg';
                this.coverImageUrl = user.coverImage?.url
                    ? user.coverImage?.url + '?ts=' + new Date().getTime()
                    : '/assets/cover_default.jpg';
                this.logoImageUrl = user.logo?.url
                    ? user.logo?.url + '?ts=' + new Date().getTime()
                    : '/assets/profile_default.jpg';
            }
        });
    }

    changeCover() {
        this.currentImageUpload = 'cover';
        this.fileUploadRef?.nativeElement.click();
    }

    changeProfile(event: Event) {
        event.stopPropagation();
        this.currentImageUpload = 'profile';
        this.fileUploadRef?.nativeElement.click();
    }

    changeLogo(event: Event) {
        event.stopPropagation();
        this.currentImageUpload = 'logo';
        this.fileUploadRef?.nativeElement.click();
    }

    onImagePicked(event: Event) {
        this.form.markAsDirty();
        const e = event.target as HTMLInputElement;
        if (!e || !e.files?.length) {
            return;
        }
        const file = e.files[0];
        const reader = new FileReader();
        if (this.currentImageUpload === 'cover') {
            reader.onload = (e) => (this.coverPreview = reader.result);
            this.form.patchValue({ coverImage: file });
        } else if (this.currentImageUpload === 'profile') {
            reader.onload = (e) => (this.profilePreview = reader.result);
            this.form.patchValue({ profileImage: file });
        } else if (this.currentImageUpload === 'logo') {
            reader.onload = (e) => (this.logoPreview = reader.result);
            this.form.patchValue({ logo: file });
        }
        reader.readAsDataURL(file);
    }

    submit() {
        this.isLoading = true;
        if (this.form.invalid) {
            this.isLoading = false;
            return;
        }

        const profileFormData = this.form.controls.profileImage.value ? new FormData() : null;
        if (profileFormData) {
            profileFormData.append('files', this.form.controls.profileImage.value as File);
        }

        const coverFormData = this.form.controls.coverImage.value ? new FormData() : null;
        if (coverFormData) {
            coverFormData.append('files', this.form.controls.coverImage.value as File);
        }

        const logoFormData = this.form.controls.logo.value ? new FormData() : null;
        if (logoFormData) {
            logoFormData.append('files', this.form.controls.logo.value as File);
        }

        const user = this.form.value as Partial<User>;

        iif(() => !!profileFormData, this.uploadService.upload(profileFormData as FormData), of(null))
            .pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap((profileResponse) => {
                    return iif(
                        () => !!coverFormData,
                        this.uploadService.upload(coverFormData as FormData),
                        of(null),
                    ).pipe(
                        switchMap((coverResponse) => {
                            return iif(
                                () => !!logoFormData,
                                this.uploadService.upload(logoFormData as FormData),
                                of(null),
                            ).pipe(
                                switchMap((logoResponse) => {
                                    return this.userService.updateUser({
                                        privateVault: user.privateVault,
                                        fullName: user.fullName,
                                        description: user.description,
                                        ...(logoResponse?.[0]?.id ? { logo: logoResponse[0].id } : {}),
                                        ...(profileResponse?.[0]?.id ? { profileImage: profileResponse[0].id } : {}),
                                        ...(coverResponse?.[0]?.id ? { coverImage: coverResponse[0].id } : {}),
                                    });
                                }),
                            );
                        }),
                    );
                }),
            )
            .subscribe({
                next: (res) => {
                    this.userService
                        .getUser()
                        .pipe(take(1))
                        .subscribe({
                            next: () => {
                                this.isLoading = false;
                                this.router.navigate(['/ideas']);
                            },
                        });
                },
                error: (error) => {
                    this.isLoading = false;
                    this.error = error?.error?.message || JSON.stringify(error);
                    console.error('Error updating user', error);
                },
            });
    }

    openImageModal() {
        this.dialogRef = this.dialog.open(EditProfileCoverPicturesComponent, {
            width: '500px',
            disableClose: false,
        });
        this.dialogRef
            .afterClosed()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: { cover: File; profile: File }) => {
                if (res.cover) {
                    this.form.markAsDirty();
                    this.previewImage(res.cover, 'cover');
                    this.form.patchValue({ coverImage: res.cover });
                }
                if (res.profile) {
                    this.form.markAsDirty();
                    this.previewImage(res.profile, 'profile');
                    this.form.patchValue({ profileImage: res.profile });
                }
            });
    }

    previewImage(file: File, previewType: 'cover' | 'profile') {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (previewType === 'cover') {
                this.coverPreview = reader.result;
            } else {
                this.profilePreview = reader.result;
            }
        };
        reader.readAsDataURL(file);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
