import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import {DatePipe, NgClass, NgIf, NgOptimizedImage, NgTemplateOutlet} from '@angular/common';
import { AuthService } from '@/app/services';
import { first } from 'rxjs';
import { ApiError } from '@/types';
import { InvalidTokenComponent } from '@/app/components/invalid-token/invalid-token.component';
import { CodeInputModule } from 'angular-code-input';
import { MatDialogContent } from '@angular/material/dialog';
import {DialogRef} from '@angular/cdk/dialog';

@Component({
    selector: 'app-verify-code',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        RouterLink,
        NgIf,
        NgClass,
        InvalidTokenComponent,
        CodeInputModule,
        NgTemplateOutlet,
        MatDialogContent,
        DatePipe,
    ],
    templateUrl: './verify-code.component.html',
    styleUrl: './verify-code.component.scss',
})
export class VerifyCodeComponent {
    @Input() identifier: string = '';
    @Input() returnUrl?: string;
    @Input() isModal?: boolean;
    @Output() onSuccess = new EventEmitter();
    @Output() onCancel = new EventEmitter();
    isLoading = false;
    isExpired = false;
    error = '';
    form = this.formBuilder.group({
        code: ['', Validators.required],
    });
    code: string = '';
    countdown: number = 120;
    canRegenerate: boolean = false;
    isNewCodeSent = false;
    intervalId: any;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        if (!this.identifier) {
            this.router.navigate(['/']);
        }
        this.startCountdown();
    }

    startCountdown() {
        this.countdown = 120;
        this.canRegenerate = false;

        this.intervalId = setInterval(() => {
            this.countdown--;
            if (this.countdown === 0) {
                this.canRegenerate = true;
                clearInterval(this.intervalId);
            }
        }, 1000);
    }

    onCodeChanged(code: string) {
        this.code = code;
    }

    get isValid() {
        return this.code.length === 6;
    }

    onSubmit() {
        if (!this.isValid) {
            return;
        }
        this.isLoading = true;
        this.authService
            .verifyCode({
                identifier: this.identifier,
                code: this.code,
            })
            .pipe(first())
            .subscribe({
                next: (response) => {
                    if (this.returnUrl) {
                        this.router.navigate([this.returnUrl]);
                    }
                    this.isLoading = false;
                    this.onSuccess.emit(true);
                },
                error: ({ error }: { error: ApiError }) => {
                    this.error = error?.error?.message || JSON.stringify(error);
                    this.isLoading = false;
                    if ((error?.error.details as any).isExpired) {
                        this.isExpired = true;
                    }
                },
            });
    }

    regenerateVerificationCode() {
        this.isLoading = true;
        this.authService
            .regenerateVerificationCode({
                identifier: this.identifier,
                code: this.code,
            })
            .pipe(first())
            .subscribe({
                next: (response) => {
                    this.isExpired = false;
                    this.error = '';
                    this.isLoading = false;
                    this.canRegenerate = false;
                    this.isNewCodeSent = true;
                    this.startCountdown();
                },
                error: ({ error }: { error: ApiError }) => {
                    this.error = error?.error?.message || JSON.stringify(error);
                    this.isLoading = false;
                },
            });
    }

    cancel() {
        this.onCancel.emit(true);
    }
}
