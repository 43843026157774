<div class="card-body justify-content-center p-4">
    <div *ngIf="isConfirmed" class="alert alert-success text-center">
        <strong>Congratulations!</strong> Your email address has been confirmed
    </div>
    <h1>Your Ideas Are Valuable</h1>
    <p class="text-center">
        {{ manifest.name }} is the patented blockchain-based solution for Intellectual Property protection. It’s
        much faster, easier, and cheaper than traditional means.
    </p>
    <p class="text-center">
        <b>Limited Time<br /></b>
        <b>New Users Enjoy 1 FREE {{ manifest.name }} Credit</b>
    </p>
    <a [routerLink]="['/sign-up']" class="btn btn-primary w-100 mt-4"> PROTECT MY IDEAS </a>
    <br />&nbsp;
    <p class="text-center">
        <b>Ready to take it to the next level?<br /></b>
        <b>Check out our Enterprise Plan.
        <a class="note" style="text-decoration: none;" href="https://www.instantip.today/enterprise" target="_blank">
             Learn more
            <img src="/assets/icons/redirect-icon.png" width="12" height="12" style="margin: 2px; margin-left: 5px;" priority />
        </a>
        </b>
    </p>
    <a [routerLink]="['/enterprise-sign-up']" class="btn btn-primary w-100 mt-4"> GET ENTERPRISE PLAN </a>
</div>
<div class="card-footer pb-5 text-center">
    <a href="https://www.instantip.today" target="_blank">Powered by {{ manifest.name }}</a>
    <p class="mt-5 mb-2 text-black"><strong>Already Have an Account?</strong></p>
    <a [routerLink]="['/sign-in']">Sign In</a>
</div>
