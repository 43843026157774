import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@/environments/environment';
import { Endpoint } from '@/enums';

@Injectable({ providedIn: 'root' })
export class UploadService {
    constructor(private http: HttpClient) {}

    upload(
        data: FormData,
    ) {
        return this.http.post<any>(`${environment.apiUrl + Endpoint.UPLOAD}`, data, {
            withCredentials: true,
        });
    }
}
