<div class="search-container mb-3 d-flex">
    <input
        class="form-control flex-grow-1"
        type="text"
        placeholder="Search for IP and companies ..."
        [formControl]="searchControl"
        (keyup.enter)="onSearch()"
        style="outline: none; box-shadow: none;"
    />
    <button
        class="btn btn-primary ms-2"
        type="button"
        (click)="onSearch()"
    >
        Search
    </button>
    <button
        *ngIf="searchControl.value"
        class="btn btn-secondary ms-2"
        type="button"
        (click)="clearSearch()"
    >
        Clear
    </button>
</div>
<app-showroom-nav />

<ul class="list">
    <li>
    <div class="row row-cols-1 row-cols-md-5 g-4" *ngIf="collections.length">
        <div class="col" *ngFor="let collection of collections">
            <div class="card h-100">
                <a [routerLink]="['/protected/companies', collection.attributes.slug || collection.id]">
                    <div class="card-img-top text-center p-3"
                        style="border: 1px #cccccc solid; border-radius: 8px; aspect-ratio: 1; width: 100%; overflow: hidden; position: relative; display: flex; align-items: center; justify-content: center;">
                        <img
                            [src]="collection.attributes.profileImage || '/assets/profile_default.jpg'"
                            class="img-fluid"
                            alt="Thumbnail"
                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; border-radius: 8px;"
                            />
                    </div>
                    <div class="card-body text-center">
                        <small class="d-block"
                                style="word-break: keep-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                        >{{ collection.attributes.name }}</small>
                    </div>
                </a>
            </div>
        </div>
    </div>
</li>
</ul>
    
<div *ngIf="!isLoading && pagination.pageCount > 1" class="d-flex justify-content-center mt-4">
    <ul class="pagination">
        <li *ngFor="let page of [].constructor(pagination.pageCount); let i = index" class="page-item" aria-current="page">
            <a
                class="page-link"
                [routerLink]="['/protected/companies']"
                [queryParams]="{ page: i+1 }"
                [ngClass]="{
                    active: pagination.page === i+1
                }"
                >{{ i+1 }}</a
            >
        </li>
    </ul>
</div>
<div class="text-center pb-5 pt-3" *ngIf="!collections.length && !isLoading && showEmptyInfo">
    <strong>There are no public collections yet</strong>
</div>
<div *ngIf="isLoading" class="p-5 my-5 d-flex justify-content-center">
    <div class="spinner-border">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
