import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@/environments/environment';
import { User } from '@/app/models';
import { Endpoint, Token } from '@/enums';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
    private userSubject: BehaviorSubject<User | null>;
    public user$: Observable<User | null>;

    constructor(private http: HttpClient) {
        this.userSubject = new BehaviorSubject<User | null>(null);
        this.user$ = this.userSubject.asObservable();
    }

    public get user(): User | null {
        return this.userSubject.value;
    }

    // Method to fetch the user data and update the BehaviorSubject
    getUser(): Observable<User> {
        return this.http
            .get<User>(`${environment.apiUrl + Endpoint.ME}?populate=wallet,logo,coverImage,profileImage`, {
                withCredentials: true,
            })
            .pipe(
                map((user) => {
                    this.userSubject.next(user);
                    return user; // Return the user data for subscribers
                }),
                catchError((error) => {
                    console.error('Failed to fetch user data', error);
                    throw error; // Or handle the error more gracefully
                }),
            );
    }

    // Method to update user data and update the BehaviorSubject
    updateUser(data: {
        fullName?: string;
        email?: string;
        tokenTracker?: string;
        logo?: string;
        hideOnboarding?: boolean;
        privateVault?: string;
        description?: string;
        coverImage?: string;
        profileImage?: string;
        phone?: string;
        enable2FA?: boolean;
    }): Observable<User & { codeSent?: boolean }> {
        return this.http
            .put<User & { codeSent?: boolean }>(`${environment.apiUrl + Endpoint.USERS}/${this.user?.id}`, data, {
                withCredentials: true,
            })
            .pipe(
                map((response) => {
                    if (!response.codeSent) {
                        this.userSubject.next(response);
                    }
                    return response;
                }),
                catchError((error) => {
                    console.error('Failed to update user data', error);
                    throw error.error; // Or handle the error more gracefully
                }),
            );
    }

    confirmEmailChange(emailConfirmation: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl + Endpoint.CONFIRM_EMAIL_CHANGE}?token=${emailConfirmation}`, {
            withCredentials: true,
        });
    }
}
