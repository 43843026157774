<div class="text-danger text-center pt-5 pb-4">
    <mat-icon class="d-flex mx-auto mb-3" fontIcon="warning" />
    It looks like your order is still pending payment.
    <strong>If you haven't paid yet, proceed to pay now.</strong>
</div>
<button class="btn btn-primary w-100 mb-3" [disabled]="isLoading" (click)="getStripeSession()">
    <span *ngIf="isLoading" class="spinner-border">
        <span class="visually-hidden">Loading...</span>
    </span>
    <span *ngIf="!isLoading">Pay Now</span>
</button>
