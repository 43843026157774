import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Token, Endpoint } from '@/enums';
import { environment } from '@/environments/environment';
import { StorageService } from '@/app/services/storage.service';
import { User } from '@/app/models/user';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private tokenSubject: BehaviorSubject<string | null>;
    public token$: Observable<string | null>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private storageService: StorageService,
    ) {
        this.tokenSubject = new BehaviorSubject<string | null>(storageService.get(Token.ACCESS) || null);
        this.token$ = this.tokenSubject.asObservable();
    }

    public get token(): string | null {
        return this.tokenSubject.value;
    }

    login({ identifier, password }: { identifier: string; password: string }) {
        return this.http
            .post<any>(environment.apiUrl + Endpoint.LOGIN, { identifier, password }, { withCredentials: true })
            .pipe(
                map((response: { jwt?: string; user?: User; codeSent?: boolean }) => {
                    if (response.jwt) {
                        this.tokenSubject.next(response.jwt);
                        this.storageService.set(Token.ACCESS, response.jwt);
                    }
                    return response;
                }),
            );
    }

    verifyCode({ identifier, code }: { identifier: string; code: string }) {
        return this.http
            .post<any>(environment.apiUrl + Endpoint.VERIFY_CODE, { identifier, code }, { withCredentials: true })
            .pipe(
                map((response: { jwt?: string; user?: User; }) => {
                    if (response.jwt) {
                        this.tokenSubject.next(response.jwt);
                        this.storageService.set(Token.ACCESS, response.jwt);
                    }
                    return response;
                }),
            );
    }

    regenerateVerificationCode({ identifier, code }: { identifier: string; code: string }) {
        return this.http
            .post<any>(environment.apiUrl + Endpoint.REGENERATE_VERIFICATION_CODE, { identifier, code }, { withCredentials: true })
            .pipe(
                map((response: { codeSent?: boolean; }) => {
                    return response;
                }),
            );
    }

    register({
        email,
        password,
        fullName,
        isEnterprise,
    }: {
        email: string;
        password: string;
        fullName: string;
        isEnterprise: boolean;
    }) {
        return this.http.post<any>(
            environment.apiUrl + Endpoint.REGISTER,
            { email, username: email, password, fullName, isEnterprise },
            { withCredentials: true },
        );
        // .pipe(
        //     map((response: { jwt: string; user: User }) => {
        //         this.tokenSubject.next(response.jwt);
        //         this.storageService.set(Token.ACCESS, response.jwt);
        //     }),
        // );
    }

    remindPassword(email: string) {
        return this.http.post<any>(environment.apiUrl + Endpoint.REMIND_PASSWORD, { email }, { withCredentials: true });
    }

    resetPassword({
        password,
        passwordConfirmation,
        code,
    }: {
        password: string;
        passwordConfirmation: string;
        code: string;
    }) {
        return this.http.post<any>(
            environment.apiUrl + Endpoint.RESET_PASSWORD,
            { password, passwordConfirmation, code },
            { withCredentials: true },
        );
    }

    logout() {
        this.tokenSubject.next(null);
        this.storageService.remove(Token.ACCESS);
        sessionStorage.removeItem(Token.TEMP);
        this.router.navigate(['/sign-in']);
    }

    confirm(confirmation: string): Observable<void> {
        return this.http
            .get<any>(`${environment.apiUrl + Endpoint.CONFIRMATION}?confirmation=${confirmation}`, {
                withCredentials: true,
            })
            .pipe(
                map((response: { jwt: string; user: User }) => {
                    this.tokenSubject.next(response.jwt);
                    this.storageService.set(Token.ACCESS, response.jwt);
                }),
            );
    }

    resendConfirmation(email: string): Observable<{
        email: string;
        sent: boolean;
    }> {
        return this.http.post<{
            email: string;
            sent: boolean;
        }>(
            `${environment.apiUrl + Endpoint.RESEND_CONFIRMATION}`,
            { email },
            {
                withCredentials: true,
            },
        );
    }
}
