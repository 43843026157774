<mat-drawer-container class="example-container" [hasBackdrop]="true">
    <mat-drawer #drawer mode="over">
        <app-side-nav (onNavToggle)="toggleDrawer()" />
    </mat-drawer>
    <mat-drawer-content>
        <app-toolbar *ngIf="user && !isPublicWebsite" (onNavToggle)="toggleDrawer()" />
        <div class="container">
            <div 
                [ngClass]="{
                    'col-md-12': isPublicWebsite,
                    'col-md-6': !isPublicWebsite
                }"
                class="mx-auto">
                <a *ngIf="!user && !isPublicWebsite" [routerLink]="['/']" class="main-logo">
                    <img ngSrc="/assets/logo_black.png" width="334" height="274" priority />
                </a>
                <div *ngIf="isPublicWebsite">
                    &nbsp;
                </div>
                <div class="card main-content">
                    <div *ngIf="isLoading" class="p-5 my-5 d-flex justify-content-center">
                        <div class="spinner-border">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <router-outlet *ngIf="!isLoading" />
                </div>
                <footer>{{ appName }} is US Patent: 11,928,748</footer>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>