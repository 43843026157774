import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ManifestService {
    private manifestUrl = 'assets/site.webmanifest';

    constructor(private http: HttpClient) {}

    getManifest(): Observable<any> {
        return this.http.get<any>(this.manifestUrl);
    }
}
