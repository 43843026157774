<ng-template #content>
    <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
    <button *ngIf="isExpired" class="btn btn-success mx-auto my-4" (click)="regenerateVerificationCode()">
        <span *ngIf="isLoading" class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </span>
        <span *ngIf="!isLoading">Resend Verification Code</span>
    </button>
    <form *ngIf="!isExpired" id="sign-in-form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <p class="alert alert-info mb-4">
            We've sent you a text message with a <strong>verification code</strong>. Please enter the code in the fields
            below.
        </p>
        <div class="mb-4" *ngIf="!isExpired">
            <div *ngIf="!canRegenerate">
                <p *ngIf="isNewCodeSent" class="text-success mb-0">New verification code has been sent.</p>
                <p>
                    You can send a new verification code in <strong>{{ countdown * 1000 | date: 'mm:ss' }}</strong>
                </p>
            </div>
            <button
                *ngIf="canRegenerate && !isExpired"
                class="btn btn-link mx-auto my-4"
                (click)="regenerateVerificationCode()"
            >
                Resend Verification Code
            </button>
        </div>
        <div class="mb-3">
            <label class="form-label" for="code"><strong>Verification Code</strong></label>
            <code-input
                [isCodeHidden]="false"
                [codeLength]="6"
                (codeChanged)="onCodeChanged($event)"
                (codeCompleted)="onSubmit()"
                id="code"
            >
            </code-input>
        </div>
        <button class="btn btn-primary w-100 mt-5" type="submit" [disabled]="!this.isValid || isLoading">
            <span *ngIf="isLoading" class="spinner-border">
                <span class="visually-hidden">Loading...</span>
            </span>
            <span *ngIf="!isLoading">Submit</span>
        </button>
        <button
            class="btn btn-outline-secondary w-100 mt-2 mb-3"
            type="button"
            [disabled]="isLoading"
            (click)="cancel()"
        >
            Cancel
        </button>
    </form>
</ng-template>

<mat-dialog-content *ngIf="isModal">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</mat-dialog-content>

<ng-container *ngIf="!isModal">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
