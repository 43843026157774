import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { RouterModule } from '@angular/router';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { UploadService } from '@/app/services/upload.service';
import { UserService } from '@/app/services';
import { User } from '@/app/models';
import {
    TwoFactorAuthSettingsComponent
} from '@/app/components/two-factor-auth-settings/two-factor-auth-settings.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        NgOptimizedImage,
        TwoFactorAuthSettingsComponent,
    ],
    selector: 'nmap-modal-component',
    templateUrl: './onboarding.component.html',
    styleUrl: './onboarding.component.scss',
})
export class OnboardingComponent {
    public user: User | null = null;
    readonly form = new FormGroup({
        tokenTracker: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required],
        }),
        logo: new FormControl<File | null>(null, {
            nonNullable: true,
            validators: [Validators.required],
        }),
    });
    public isLoading = {
        submit: false,
        cancel: false,
    };
    public error: string | null = null;
    public title = `Please Provide Additional IP Info`;
    public description = `(Note: These Details Can Never Be Changed Due To Blockchain Technology)`;
    private ngUnsubscribe = new Subject<void>();
    constructor(
        public dialogRef: MatDialogRef<OnboardingComponent>,
        private uploadService: UploadService,
        private userService: UserService,
    ) {}

    ngOnInit() {
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
            if (this.user?.tokenTracker) {
                this.form.controls.tokenTracker.setValue(this.user?.tokenTracker);
                this.form.controls.tokenTracker.disable();
            }
        });
    }

    onImagePicked(event: Event) {
        const e = event.target as HTMLInputElement;
        if (!e || !e.files?.length) {
            return;
        }
        const file = e.files[0];
        this.form.patchValue({ tokenTracker: this.form.controls.tokenTracker.value, logo: file });
    }

    onSubmit() {
        if (this.form.invalid) {
            return;
        }
        this.isLoading.submit = true;
        const formData = new FormData();
        formData.append('files', this.form.controls.logo.value as File);
        this.uploadService
            .upload(formData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((response) => {
                this.userService
                    .updateUser({
                        tokenTracker: this.form.controls.tokenTracker.value,
                        logo: response[0].id,
                        profileImage: response[0].id,
                    })
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(() => {
                        this.isLoading.submit = false;
                        this.dialogRef.close();
                    });
            });
    }

    onCancel() {
        this.isLoading.cancel = true;
        this.userService
            .updateUser({
                hideOnboarding: true,
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.isLoading.cancel = false;
                this.dialogRef.close();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
