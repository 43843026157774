<div class="card-body justify-content-center p-4">
    <div class="d-flex text-start align-items-center justify-content-between pt-3 pb-4 mb-3">
        <h1 class="m-0">Account Security</h1>
    </div>
    <div *ngIf="isTokenInvalid" class="alert alert-danger">
        Could not change email address, probably the link has expired or this email address has already been taken.
        Please try again.
    </div>
    <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
    <div *ngIf="isEmailChanged" class="alert alert-success">
        You have requested a change of email, a confirmation link has been sent to the new address. Check your mailbox
        and click the link to complete the change.
    </div>
    <div *ngIf="isEmailChangeConfirmed" class="alert alert-success">
        The email has been successfully changed, you can now use it to log in.
    </div>

    <label for="email" class="form-label">Your Verified Email Address</label>
    <input
        class="form-control form-control-lg mb-3"
        type="text"
        placeholder="Email"
        id="email"
        [disabled]="true"
        [value]="user?.email"
    />
    <button class="btn btn-primary w-100 mb-4 align-self-end" (click)="changeEmail()">Change Email</button>
    <hr />
    <div style="margin: 2px;">
        <app-two-factor-auth-settings />
    <div>
</div>
