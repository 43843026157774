import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MatDialogModule, NgOptimizedImage],
    selector: 'app-public-confirm',
    templateUrl: './public-confirm.component.html',
    styleUrl: './public-confirm.component.scss',
})
export class PublicConfirmComponent {
    readonly form = new FormGroup({
        confirm: new FormControl<boolean>(false, {
            nonNullable: true,
            validators: [Validators.required],
        }),
    });
    private ngUnsubscribe = new Subject<void>();
    constructor(public dialogRef: MatDialogRef<PublicConfirmComponent>) {}

    async onSubmit() {
        if (this.form.invalid) {
            return;
        }
        this.dialogRef.close(true);
    }

    onCancel() {
        this.dialogRef.close(false);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
