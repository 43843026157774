<nav class="navbar navbar-expand-lg mb-1">
    <div class="container">
        <div>
            <a class="navbar-brand me-2" [routerLink]="['/ideas']">
                <img ngSrc="/assets/logo_black.png" width="83" height="68" priority />
            </a>
            <span *ngIf="user?.isEnterprise" class="badge text-bg-dark">Enterprise User</span>
        </div>

        <button class="navbar-toggler d-block" type="button" (click)="toggleNav()">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>
</nav>
