<div class="card-body justify-content-center p-4">
    <h1>Set New Password</h1>
    <div id="form-wrapper">
        <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
        <div *ngIf="submitted" class="alert alert-success">
            The new password has been set, soon you will be redirected to the login page
        </div>
        <form id="sign-in-form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mb-3">
                <label class="form-label" for="password">New password</label>
                <input
                    class="form-control form-control-lg"
                    [ngClass]="{
                        'is-invalid': form.controls.password.touched && form.controls.password.errors
                    }"
                    id="password"
                    formControlName="password"
                    type="password"
                    required
                />
                <div class="invalid-feedback" *ngIf="form.controls.password.hasError('required')">Required field</div>
                <div class="invalid-feedback" *ngIf="form.controls.password.hasError('minlength')">
                    Password must have at least 8 characters
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label" for="repeatPassword">Repeat new password</label>
                <input
                    class="form-control form-control-lg"
                    [ngClass]="{
                        'is-invalid': form.controls.repeatPassword.touched && form.controls.repeatPassword.errors
                    }"
                    id="repeatPassword"
                    formControlName="repeatPassword"
                    type="password"
                    required
                />
                <div class="invalid-feedback" *ngIf="form.controls.repeatPassword.hasError('required')">
                    Required field
                </div>
                <div class="invalid-feedback" *ngIf="form.controls.repeatPassword.hasError('matching')">
                    The entered passwords do not match
                </div>
            </div>
            <button class="btn btn-primary w-100 mt-5 mb-3" type="submit" [disabled]="isLoading || !form.valid">
                <span *ngIf="isLoading" class="spinner-border">
                    <span class="visually-hidden">Loading...</span>
                </span>
                <span *ngIf="!isLoading">Submit</span>
            </button>
        </form>
    </div>
</div>
