<div class="card-body justify-content-center p-4">
    <h1>Invalid Confirmation Link</h1>
    <ng-container *ngIf="!isSent">
        <p class="alert alert-warning mb-4">
            You used an invalid or expired email confirmation link. To generate a new link, enter the email address you
            used to register.
        </p>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mb-3">
                <label class="form-label" for="token-email">Email address</label>
                <input
                    class="form-control form-control-lg"
                    id="token-email"
                    type="text"
                    formControlName="email"
                    [ngClass]="{
                        'is-invalid': form.controls.email.touched && form.controls.email.errors
                    }"
                    required
                />
                <div class="invalid-feedback" *ngIf="form.controls.email.hasError('required')">Required field</div>
                <div class="invalid-feedback" *ngIf="form.controls.email.hasError('email')">
                    Incorrect email address
                </div>
            </div>
            <button class="btn btn-primary w-100 mt-5 mb-3" type="submit" [disabled]="!form.valid">
                <span *ngIf="isLoading" class="spinner-border">
                    <span class="visually-hidden">Loading...</span>
                </span>
                <span *ngIf="!isLoading">Submit</span>
            </button>
        </form>
    </ng-container>
    <ng-container *ngIf="isSent">
        <p class="alert alert-success mb-4">
            An email has been sent to the provided address. Please check your email inbox for a confirmation message.
            Inside, you'll find a link to activate your account.
        </p>
    </ng-container>
</div>
