import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatFabButton, MatMiniFabButton } from '@angular/material/button';
import { IdeasListComponent } from '@/app/components/ideas-list/ideas-list.component';
import { PublicIdeasListComponent } from '@/app/components/public-ideas-list/public-ideas-list.component';

@Component({
    selector: 'app-public-ideas',
    standalone: true,
    imports: [
        CommonModule,
        MatIcon,
        MatFabButton,
        MatMiniFabButton,
        RouterLink,
        IdeasListComponent,
        PublicIdeasListComponent,
    ],
    templateUrl: './public-ideas.component.html',
    styleUrls: ['./public-ideas.component.scss'],
})
export class PublicIdeasComponent {}