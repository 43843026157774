<div class="card-body justify-content-center p-4">
    <div class="d-flex text-start align-items-center justify-content-between pt-3 pb-4 mb-3">
        <h1 class="m-0">Profile</h1>
    </div>
    <div>
        <form [formGroup]="form">
            <div class="images-wrapper">
                <div class="cover-img" (click)="changeCover()">
                    <img *ngIf="coverPreview || coverImageUrl" [src]="coverPreview || coverImageUrl" fill priority />
                </div>
                <a
                    class="img-popup-text"
                    (click)="openImageModal()"
                    style="
                        position: relative;
                        display: inline-block;
                        white-space: normal;
                        text-indent: 0;
                        margin-left: 130px;
                    "
                >
                    Edit profile and cover pictures
                </a>
                <div class="profile-img" (click)="changeProfile($event)">
                    <img
                        *ngIf="profilePreview || profileImageUrl"
                        [src]="profilePreview || profileImageUrl"
                        fill
                        priority
                    />
                </div>
            </div>
            <div *ngIf="error" class="alert alert-danger">{{ error }}</div>

            <label for="fullName" class="form-label">Full Name</label>
            <input
                class="form-control form-control-lg mb-3"
                type="text"
                formControlName="fullName"
                placeholder="Name"
                id="fullName"
                required
            />

            <ng-container *ngIf="user?.isEnterprise">
                <label for="tokenTracker" class="form-label"
                    >Token Tracker - cannot be modified after written on blockchain</label
                >
                <input
                    class="form-control form-control-lg mb-4"
                    type="text"
                    formControlName="tokenTracker"
                    placeholder="i.e. John Smith IP"
                    id="tokenTracker"
                    required
                />
                <div class="mb-4 share-qr">
                    <a href="/assets/share-qr-ip.png" target="_blank">
                        <img ngSrc="/assets/share-qr-ip.png" width="100" height="100" priority />
                    </a>
                    <div class="share-qr__text">
                        Protected by Instant IP™ QR code.
                        <a href="https://www.instantip.today/enterprise" target="_blank">
                            Read more<img
                                src="/assets/icons/redirect-icon.png"
                                width="12"
                                height="12"
                                style="margin-left: 5px; margin: 3px"
                                priority
                            />
                        </a>
                    </div>
                </div>
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> Advanced Settings </mat-panel-title>
                        </mat-expansion-panel-header>
                        <label for="privateVault" class="form-label"
                            >Your wallet address (on Polygon network) for Protected IP Transfer</label
                        >
                        <input
                            class="form-control form-control-lg mb-3"
                            type="text"
                            formControlName="privateVault"
                            id="privateVault"
                        />
                        <a class="note" href="https://www.instantip.today/join-enterprise" target="_blank">
                            Protected IP Transfer feature is for advanced blockchain users only. Read more
                            <img
                                src="/assets/icons/redirect-icon.png"
                                width="12"
                                height="12"
                                style="margin: 2px; margin-left: 5px"
                                priority
                            />
                        </a>
                    </mat-expansion-panel>
                </mat-accordion>
            </ng-container>
        </form>
        <button class="btn btn-primary w-100 mt-5 align-self-end" [disabled]="isLoading" (click)="submit()">
            <div *ngIf="isLoading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <ng-container *ngIf="!isLoading">Save changes</ng-container>
        </button>
    </div>
</div>
<input
    style="max-width: 100%; white-space: break-spaces; visibility: hidden"
    type="file"
    required
    id="logoUpload"
    (change)="onImagePicked($event)"
    accept="image/png,image/svg+xml,image/jpg,image/gif"
    #fileUploadRef
/>
