<div class="card-body justify-content-center p-4" style="overflow-anchor: none">
    <ng-container *ngIf="showEnterText">
        <app-enter-text (onClose)="toggleTextEnter()" />
    </ng-container>
    <ng-container *ngIf="!showEnterText">
        <h1 *ngIf="!isUploading">Protect Your Idea</h1>
        <h1 *ngIf="isUploading">Uploading your Ideas</h1>
        <h2 *ngIf="!ideas.length">How would you like to send your Idea?</h2>
        <ng-container *ngIf="ideas.length">
            <h2 *ngIf="!isUploading">Name and Describe your Ideas</h2>
            <div class="text-center mb-5">
                <span>(Note: These Details Can Never Be Changed Due To Blockchain Technology)</span>
            </div>
            <h2 *ngIf="isUploading">Please wait...</h2>
            <div
                *ngFor="let idea of ideas; index as i"
                [ngClass]="['mb-2', idea.progress === 100 ? 'idea idea-uploaded' : 'idea']"
            >
                <div class="idea-content d-flex">
                    <div class="thumbnail me-3">
                        <mat-icon
                            *ngIf="idea.ideaType === IdeaType.FILE"
                            fontIcon="insert_drive_file"
                            class="overflow-visible"
                        />
                        <mat-icon *ngIf="idea.ideaType === IdeaType.TEXT" fontIcon="article" class="overflow-visible" />
                        <mat-icon
                            *ngIf="idea.ideaType === IdeaType.IMAGE && !idea.preview"
                            fontIcon="image"
                            class="overflow-visible"
                        />
                        <img *ngIf="idea.ideaType === IdeaType.IMAGE && idea.preview" [src]="idea.preview" />
                    </div>
                    <div class="d-flex flex-grow-1 justify-content-between align-items-start">
                        <div class="d-flex flex-column flex-grow-1">
                            <input
                                *ngIf="!isUploading && idea.ideaType"
                                class="form-control form-control-lg"
                                type="text"
                                [value]="idea.title"
                                placeholder="Name your Idea"
                                (change)="updateIdea($event, idea.uid, 'title')"
                                required
                            />
                            <div style="word-break: break-all" *ngIf="isUploading">{{ idea.title }}</div>
                            <textarea
                                class="form-control form-control-lg mt-2"
                                placeholder="Describe your Idea"
                                (change)="updateIdea($event, idea.uid, 'description')"
                                *ngIf="!isUploading && idea.ideaType"
                                autosize
                                >{{ idea.description }}</textarea
                            >
                            <mat-slide-toggle
                                *ngIf="!isUploading && idea.ideaType"
                                class="mt-3"
                                color="primary"
                                [checked]="idea.isPublic"
                                (change)="updateIdea($event, idea.uid, 'isPublic')"
                                [disabled]="!user?.isEnterprise"
                            >
                                <span class="ms-2" *ngIf="!idea.isPublic">Hidden (Your Idea CAN’T be seen)</span>
                                <span class="ms-2" *ngIf="idea.isPublic">Public (Your Idea CAN be seen) </span>
                            </mat-slide-toggle>
                            <small class="d-block mt-3" *ngIf="!user?.isEnterprise && !isUploading && idea.ideaType">
                                <a href="https://www.instantip.today/join-enterprise" target="_blank">
                                    Public Ideas are available on <strong>Enterprise Plan</strong>.<br /><span
                                        class="text-decoration-underline"
                                        >Read how to upgrade</span
                                    >
                                    <img
                                        src="/assets/icons/redirect-icon.png"
                                        width="12"
                                        height="12"
                                        style="margin: 2px; margin-left: 5px"
                                        priority
                                    />
                                </a>
                            </small>
                        </div>
                        <button
                            class="btn p-0 ms-3"
                            style="margin-top: 11px"
                            (click)="deleteIdea(idea)"
                            *ngIf="!isUploading"
                        >
                            <img ngSrc="/assets/icons/delete.svg" width="24" height="24" />
                        </button>
                        <mat-icon
                            *ngIf="isUploading && idea.progress === 100"
                            fontIcon="check_circle"
                            class="text-success overflow-visible ms-3"
                        />
                    </div>
                </div>
                <mat-progress-bar *ngIf="isUploading" mode="determinate" [value]="idea.progress"></mat-progress-bar>
            </div>
            <div *ngIf="isUploading" class="p-3 mt-4 d-flex justify-content-center">
                <div class="spinner-border">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </ng-container>
        <div class="my-4" *ngIf="showUploadButtons && !isUploading">
            <app-upload-idea-buttons (onTextEnter)="toggleTextEnter()" />
            <button class="btn btn-text mx-auto p-0 mt-3" *ngIf="ideas.length" (click)="toggleShowUploadButtons()">
                <img width="55" height="55" ngSrc="/assets/icons/cross-circle.svg" />
            </button>
        </div>
        <ng-container *ngIf="!showUploadButtons && !isUploading">
            <button class="btn btn-outline-secondary w-100 my-4" (click)="toggleShowUploadButtons()">
                <img class="me-2" ngSrc="/assets/icons/plus.svg" width="24" height="24" />Add Another Idea as Desired
            </button>
            <button
                class="btn btn-primary w-100 mt-5 align-self-end"
                (click)="submit()"
            >
                <ng-container *ngIf="!user">Next Step</ng-container>
                <ng-container *ngIf="user">Protect My Idea</ng-container>
            </button>
            <small class="text-center d-block text-muted mt-3">* You will be able to add more Ideas later</small>
        </ng-container>
    </ng-container>
</div>
<div *ngIf="!ideas.length && !showEnterText && !isUploading && !user" class="card-footer pb-5 pt-2 text-center">
    <p>Already have an account?</p>
    <a [routerLink]="['/sign-in']">Sign In</a>
</div>
