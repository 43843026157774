import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import { IpService } from '@/app/services';
import { Subject, takeUntil } from 'rxjs';
import { Collection } from '@/app/models';
import { ReactiveFormsModule } from '@angular/forms';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { AutosizeModule } from 'ngx-autosize';
import { PayNowComponent } from '@/app/components/shared/pay-now/pay-now.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatFormField } from '@angular/material/form-field';
import { PublicIdeasListComponent } from '@/app/components/public-ideas-list/public-ideas-list.component';
import { ProtectedIdeasNavComponent } from '../protected-ideas-nav/protected-ideas-nav.component';

@Component({
    selector: 'app-public-idea-collection-details',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        MatIcon,
        MatMiniFabButton,
        MatTooltip,
        MatButton,
        AutosizeModule,
        PayNowComponent,
        MatSlideToggle,
        MatFormField,
        PublicIdeasListComponent,
        ProtectedIdeasNavComponent,
        NgOptimizedImage,
        RouterLink,
    ],
    templateUrl: './public-idea-collection-details.component.html',
    styleUrl: './public-idea-collection-details.component.scss',
})
export class PublicIdeaCollectionDetailsComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>();
    public isLoading = {
        collection: true,
    };
    public collection: Collection | null = null;
    public collectionId: string | null = null;
    constructor(
        private router: Router,
        private ipService: IpService,
        private route: ActivatedRoute,
    ) {
        this.collectionId = this.route.snapshot.paramMap.get('id');
    }

    loadCollection() {
        if (!this.collectionId) {
            return;
        }
        this.isLoading.collection = true;
        this.ipService
            .getPublicIpCollection(this.collectionId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: Collection) => {
                    console.log('response', response);
                    this.collection = response;
                    this.isLoading.collection = false;
                },
                error: (response) => {
                    this.collection = null;
                    this.isLoading.collection = false;
                    if (response.status === 404) {
                        this.router.navigate(['/protected/companies']);
                    }
                },
            });
    }

    ngOnInit() {
        if (!this.collectionId) {
            this.router.navigate(['/protected/companies']);
        } else {
            this.loadCollection();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
