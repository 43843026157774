import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatFabButton, MatMiniFabButton } from '@angular/material/button';
import { IdeaType } from '@/enums';
import { IdeasListComponent } from '@/app/components/ideas-list/ideas-list.component';
import { UserService } from '@/app/services';
import { ProtectedIdeasNavComponent } from '../protected-ideas-nav/protected-ideas-nav.component';

@Component({
    selector: 'app-ideas',
    standalone: true,
    imports: [
        CommonModule,
        MatIcon,
        MatFabButton,
        MatMiniFabButton,
        RouterLink,
        IdeasListComponent,
        ProtectedIdeasNavComponent,
    ],
    templateUrl: './ideas.component.html',
    styleUrl: './ideas.component.scss',
})
export class IdeasComponent implements OnInit {
    private ngUnsubscribe = new Subject<void>();
    collectionId: number | undefined = undefined;

    constructor(
        private router: Router,
        private userService: UserService,
    ) {}

    ngOnInit() {
        this.collectionId = this.userService.user?.id;
    }

    addIdea() {
        this.router.navigate(['/upload-idea']);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly IdeaType = IdeaType;
}
