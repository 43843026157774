<div class="card-body justify-content-center p-4">
    <div class="d-flex align-items-center pt-3 pb-4 mb-3">
        <img 
            src="/assets/logo_black.png" 
            width="98" 
            height="80" 
            alt="Logo"
            class="me-3"
        />
        <h1 class="m-0">Protected IP</h1>
    </div>
    <app-public-idea-collections-list [showEmptyInfo]="true" />
</div>
