import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '@/app/services';
import { Subject, takeUntil } from 'rxjs';
import { Order } from '@/app/models';
import { CommonModule } from '@angular/common';
import { IdeasListComponent } from '@/app/components/ideas-list/ideas-list.component';
import { MatIcon } from '@angular/material/icon';
import { PayNowComponent } from '@/app/components/shared/pay-now/pay-now.component';
import { OrderStatus } from '@/enums';

@Component({
    selector: 'app-order-details',
    standalone: true,
    imports: [CommonModule, IdeasListComponent, MatIcon, PayNowComponent],
    templateUrl: './order-details.component.html',
    styleUrl: './order-details.component.scss',
})
export class OrderDetailsComponent {
    private ngUnsubscribe = new Subject<void>();
    public isLoading = true;
    public order: Order | null = null;
    public orderId: string | null = null;
    constructor(
        private router: Router,
        private orderService: OrderService,
        private route: ActivatedRoute,
    ) {
        this.orderId = this.route.snapshot.paramMap.get('id');
    }

    loadOrder() {
        if (!this.orderId) {
            return;
        }

        this.isLoading = true;
        this.orderService
            .getOrder(parseInt(this.orderId))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: Order) => {
                    this.order = response;
                    this.isLoading = false;
                },
                error: () => {
                    this.order = null;
                    this.isLoading = false;
                },
            });
    }

    ngOnInit() {
        if (!this.orderId) {
            this.router.navigate(['/orders']);
        } else {
            this.loadOrder();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly OrderStatus = OrderStatus;
}
