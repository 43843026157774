import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
    static match(controlName: string, matchControlName: string): ValidatorFn {
        return (controls: AbstractControl) => {
            const control = controls.get(controlName);
            const matchControl = controls.get(matchControlName);

            if (!matchControl?.errors && control?.value !== matchControl?.value) {
                matchControl?.setErrors({
                    matching: {
                        actualValue: matchControl?.value,
                        requiredValue: control?.value,
                    },
                });
                return { matching: true };
            }
            return null;
        };
    }
    static ethereum(): ValidatorFn {
        let regex = new RegExp(/^(0x)?[0-9a-fA-F]{40}$/);
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;    
            let isValid = regex.test(value);

            // Allow empty values (null or empty string)
            if (value === null || value.trim() === '') {
                isValid = true;
            }

            return isValid ? null : { ethereum: { value: control.value } };
        };
    }
    /* static ethereum(): ValidatorFn {
        let regex = new RegExp(/^(0x)?[0-9a-fA-F]{40}$/);
        return (control: AbstractControl): ValidationErrors | null => {
            const isValid = regex.test(control.value);
            return isValid ? null : { ethereum: { value: control.value } };
        };
    } */
}
