import { Ip } from '@/app/models';
import { PdfGeneratorService } from '@/app/services/pdf-generator.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-pdf-receipt',
    standalone: true,
    imports: [CommonModule, NgOptimizedImage],
    templateUrl: './pdf-receipt.component.html',
    styleUrl: './pdf-receipt.component.scss',
})
export class PdfReceiptComponent {
    @Input() ip: Ip | undefined;

    constructor(private pdfGeneratorService: PdfGeneratorService) {}

    public downloadPdf(pdfName: string): void {
        this.pdfGeneratorService.generatePdf('pdfContent', pdfName + '.pdf');
    }
}
