import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';

import { IdeaService } from '@/app/services';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'app-enter-text',
    standalone: true,
    imports: [ReactiveFormsModule, NgOptimizedImage],
    templateUrl: './enter-text.component.html',
    styleUrl: './enter-text.component.scss',
})
export class EnterTextComponent {
    @Output() onClose = new EventEmitter();

    enterTextForm = this.formBuilder.group({
        text: '',
    });

    constructor(
        private formBuilder: FormBuilder,
        private ideaService: IdeaService,
    ) {}

    cancel() {
        this.onClose.emit();
    }

    async onSubmit() {
        if (!this.enterTextForm.controls.text.value) {
            return;
        }
        await this.ideaService.addIdea({
            text: this.enterTextForm.controls.text.value,
        });
        this.onClose.emit();
    }
}
