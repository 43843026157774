import { Component, EventEmitter, Output } from '@angular/core';

import { AuthService, UserService } from '@/app/services';
import {NgClass, NgIf, NgOptimizedImage} from '@angular/common';
import { RouterLink } from '@angular/router';
import { User } from '@/app/models';
import { Subject, takeUntil } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { OnboardingComponent } from '@/app/components/onboarding/onboarding.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-side-nav',
    standalone: true,
    imports: [NgOptimizedImage, RouterLink, MatIcon, NgClass, NgIf],
    templateUrl: './side-nav.component.html',
    styleUrl: './side-nav.component.scss',
})
export class SideNavComponent {
    @Output() onNavToggle = new EventEmitter();
    private ngUnsubscribe = new Subject<void>();
    public user: User | null = null;
    public dialogRef: MatDialogRef<OnboardingComponent> | null = null;

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
        });
    }

    toggleNav() {
        this.onNavToggle.emit();
    }

    logout() {
        this.authService.logout();
        this.onNavToggle.emit();
    }

    showOnboarding(event: Event) {
        event.preventDefault();
        if (this.user) {
            this.onNavToggle.emit();
            this.dialogRef = this.dialog.open(OnboardingComponent, {
                width: '500px',
                disableClose: true,
            });
            this.dialogRef
                .afterClosed()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    this.userService.getUser();
                });
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
