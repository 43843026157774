import { Component, EventEmitter, Output } from '@angular/core';
import { IdeaService } from '@/app/services';
import { NgOptimizedImage } from '@angular/common';
@Component({
    selector: 'app-upload-idea-buttons',
    standalone: true,
    imports: [NgOptimizedImage],
    templateUrl: './upload-idea-buttons.component.html',
    styleUrl: './upload-idea-buttons.component.scss',
})
export class UploadIdeaButtonsComponent {
    public isLoading: boolean = false;
    @Output() onTextEnter = new EventEmitter();
    constructor(private ideaService: IdeaService) {}

    enterText() {
        this.onTextEnter.emit();
    }

    uploadFile() {
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.click();
        }
    }

    takePhoto() {
        const cameraInput = document.getElementById('cameraInput');
        if (cameraInput) {
            cameraInput.click();
        }
    }

    async handleFileInput(event: Event) {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;
        this.isLoading = true;
        for (let i = 0; i < Array.from(files).length; i++) {
            const file = Array.from(files)[i];
            if (file.size > 100000000) {
                this.isLoading = false;
                return alert('One of the files is too large. Please ensure all files are below 100MB.');
            }
        }
        for (let i = 0; i < Array.from(files).length; i++) {
            const file = Array.from(files)[i];
            await this.ideaService.addIdea({
                file,
            });
        }
        this.isLoading = false;
    }
}
