import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IpService } from '@/app/services';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { Ip } from '@/app/models';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IdeaType, IpType } from '@/enums';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import slugify from 'slugify';
import { AutosizeModule } from 'ngx-autosize';
import { PayNowComponent } from '@/app/components/shared/pay-now/pay-now.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatFormField } from '@angular/material/form-field';
import { ShowroomNavComponent } from '@/app/components/showroom-nav/showroom-nav.component';

@Component({
    selector: 'app-public-idea-details',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        MatIcon,
        MatMiniFabButton,
        MatTooltip,
        MatButton,
        AutosizeModule,
        PayNowComponent,
        MatSlideToggle,
        MatFormField,
        RouterLink,
        NgOptimizedImage,
        ShowroomNavComponent,
    ],
    templateUrl: './public-idea-details.component.html',
    styleUrl: './public-idea-details.component.scss',
})
export class PublicIdeaDetailsComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>();
    public isLoading = {
        ip: true,
        presignedUrl: false,
        update: false,
        delete: false,
    };
    public ip: Ip | null = null;
    public ipId: string | null = null;
    public showBack: boolean = false;
    constructor(
        private router: Router,
        private ipService: IpService,
        private route: ActivatedRoute,
    ) {
        this.ipId = this.route.snapshot.paramMap.get('id');
    }

    loadIp() {
        if (!this.ipId) {
            return;
        }
        this.isLoading.ip = true;
        this.ipService
            .getPublicIp(parseInt(this.ipId))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: Ip) => {
                    this.ip = response;
                    this.isLoading.ip = false;
                },
                error: (response) => {
                    this.ip = null;
                    this.isLoading.ip = false;
                    if (response.status === 404) {
                        this.router.navigate(['/protected']);
                    }
                },
            });
    }

    ngOnInit() {
        if (!this.ipId) {
            this.router.navigate(['/protected']);
        } else {
            this.loadIp();
        }
        this.route.queryParamMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paramMap) => {
            this.showBack = paramMap.get('collection') === 'true';
        });
    }

    async downloadIp() {
        if (this.ip?.attributes.type === IpType.Text) {
            const blob = new Blob([this.ip?.attributes.content!], { type: 'text' });
            const anchor = document.createElement('a');
            anchor.download = `${slugify(this.ip?.attributes.title).replace(/\./g, '')}.txt`;
            anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
            anchor.click();
        } else {
            this.isLoading.presignedUrl = true;
            const url = await lastValueFrom(this.ipService.getPresignedUrl(parseInt(this.ipId!)));
            this.isLoading.presignedUrl = false;

            setTimeout(() => {
                window.open(url, '_blank');
            });
        }
    }

    async downloadProtected() {
        if (this.ip?.attributes.protectedUrl != null) {
            window.open(this.ip?.attributes.protectedUrl, '_blank');
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly IdeaType = IdeaType;
}
