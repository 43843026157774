import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { UserService } from '@/app/services';
import { User } from '@/app/models';

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MatDialogModule, NgOptimizedImage],
    selector: 'app-email-change',
    templateUrl: './email-change.component.html',
    styleUrl: './email-change.component.scss',
})
export class EmailChangeComponent {
    private ngUnsubscribe = new Subject<void>();
    public isLoading = false;
    public isEmailChangeRequested = false;
    public user: User | null = null;
    public error = '';
    public form = new FormGroup({
        email: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
    });

    constructor(
        public dialogRef: MatDialogRef<EmailChangeComponent>,
        private userService: UserService,
        private router: Router,
    ) {}


    closeDialog(): void {
        this.dialogRef.close();
    }
    
    submit() {
        this.isLoading = true;
        if (this.form.invalid) {
            this.isLoading = false;
            return;
        }

        const user = this.form.value as Partial<User>;

        this.userService
            .updateUser({
                email: user.email,
            })
            .subscribe({
                next: (res) => {
                    this.isLoading = false;
                    if (this.user?.email !== user.email) {
                        this.isEmailChangeRequested = true;
                    } else {
                        this.router.navigate(['/ideas']);
                    }
                },
                error: (error) => {
                    this.isLoading = false;
                    this.error = error?.error?.message || JSON.stringify(error);
                    console.error('Error updating user', error);
                },
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
