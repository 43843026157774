import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatFabButton, MatMiniFabButton } from '@angular/material/button';
import { IdeasListComponent } from '@/app/components/ideas-list/ideas-list.component';
import { PublicIdeasListComponent } from '@/app/components/public-ideas-list/public-ideas-list.component';
import { PublicIdeaCollectionsListComponent } from '@/app/components/public-idea-collections-list/public-idea-collections-list.component';

@Component({
    selector: 'app-public-idea-collections',
    standalone: true,
    imports: [
        CommonModule,
        MatIcon,
        MatFabButton,
        MatMiniFabButton,
        RouterLink,
        IdeasListComponent,
        PublicIdeasListComponent,
        PublicIdeaCollectionsListComponent,
    ],
    templateUrl: './public-idea-collections.component.html',
    styleUrl: './public-idea-collections.component.scss',
})
export class PublicIdeaCollectionsComponent {}
