import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterLink, RouterOutlet, RouterLinkActive } from '@angular/router';
import { ManifestService, UserService } from '@/app/services';
import { Subject, interval, merge } from 'rxjs';
import { takeUntil, startWith, switchMap, filter } from 'rxjs/operators';
import { Transaction, User } from '@/app/models';
import { CommonModule } from '@angular/common';
import { IdeasListComponent } from '@/app/components/ideas-list/ideas-list.component';
import { PaginationMeta } from '@/types';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TransactionType } from '@/enums';

@Component({
  selector: 'app-billing',
  standalone: true,
  imports: [CommonModule, IdeasListComponent, RouterLink, InfiniteScrollModule, RouterOutlet, RouterLinkActive],
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  public isLoading: boolean = false;
  public user: User | null = null;
  public pagination: PaginationMeta | null = null;
  public transactions: Transaction[] = [];
  
  manifest: any = {};

  constructor(
    private userService: UserService,
    private manifestService: ManifestService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.manifestService.getManifest().subscribe((manifest) => {
      this.manifest = manifest;
    });
  }

  ngOnInit() {
    // Observable to reload on navigation
    const navigationReload$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    // Observable to reload every minute - this ensures that credits are visible after Stripe returns the information
    const intervalReload$ = interval(120000).pipe(startWith(0)); // Starts immediately, then every 2 minute

    // Merge both observables and switchMap to userService's getUser method
    merge(navigationReload$, intervalReload$).pipe(
      takeUntil(this.ngUnsubscribe),
      switchMap(() => this.userService.getUser()) // Explicitly fetch user data
    ).subscribe(
      (user) => {
        this.user = user;
      },
      (error) => {
        console.error('Failed to reload user data', error); // Optional error handling
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected readonly TransactionType = TransactionType;
}
