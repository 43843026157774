import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';

import { environment } from '@/environments/environment';
import { AuthService } from '@/app/services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    private handleAuthError(error: HttpErrorResponse): Observable<any> {
        if (error.status === 401) {
            this.authService.logout();
            return of(error.message);
        }
        return throwError(() => error);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.token;
        const isLoggedIn = token;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        const isPublicEndpoint = request.url.includes('public');
        if (isLoggedIn && isApiUrl && !isPublicEndpoint) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }

        return next.handle(request).pipe(catchError((error) => this.handleAuthError(error)));
    }
}
