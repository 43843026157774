<ul class="nav nav-tabs showroom-nav">
    <li class="nav-item">
        <a
            class="nav-link"
            [routerLink]="['/protected']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >IP</a
        >
    </li>
    <li class="nav-item">
        <a
            class="nav-link"
            [routerLink]="['/protected/companies']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Companies</a
        >
    </li>
</ul>
