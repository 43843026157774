import { Injectable } from '@angular/core';
import { Idea } from '@/app/models';
import { Endpoint, IdeaType, Token } from '@/enums';
import { v4 as uuid } from 'uuid';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import truncate from 'truncate';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { environment } from '@/environments/environment';
import pLimit from 'p-limit';
import { heifConvert } from '@/app/utils';

function readAsDataURL(file: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
}

@Injectable({
    providedIn: 'root',
})
export class IdeaService {
    public isUploading$: Observable<boolean>;
    private isUploadingSubject: BehaviorSubject<boolean>;
    public ideas$: Observable<Idea[]>;
    private ideasSubject: BehaviorSubject<Idea[]>;
    public filesSize: number = 0;

    constructor(private http: HttpClient) {
        this.ideasSubject = new BehaviorSubject<Idea[]>([]);
        this.hydrate();
        this.ideas$ = this.ideasSubject.asObservable();
        this.isUploadingSubject = new BehaviorSubject<boolean>(false);
        this.isUploading$ = this.isUploadingSubject.asObservable();
    }

    public get ideas(): Idea[] {
        return this.ideasSubject.value;
    }

    public hydrate() {
        const temp = sessionStorage.getItem(Token.TEMP);
        if (temp) {
            this.ideasSubject.next(JSON.parse(temp));
        }
    }

    public get isUploading(): boolean {
        return this.isUploadingSubject.value;
    }

    async addIdea(data: { text?: string; file?: File }) {
        const allowedImageTypes = ['png', 'jpg', 'jpeg', 'gif', 'heic', 'heif'];
        const idea: Idea = {
            uid: uuid(),
            ideaType: data.file
                ? allowedImageTypes.some((type) => data.file!.type.includes(type))
                    ? IdeaType.IMAGE
                    : IdeaType.FILE
                : IdeaType.TEXT,
            title: data.text ? truncate(data.text, 36) : '', //data.file?.name ||
            content: data.text,
            file: data.file,
            extension: data.file?.name.split('.').pop(),
            isPublic: false,
            mintOnBlockchain: true,
        };

        if (data.file) {
            this.filesSize += data.file.size;
        }

        if (idea.ideaType === IdeaType.IMAGE) {
            idea.preview = await readAsDataURL(await heifConvert(idea.file!));
        }

        this.ideasSubject.next([...this.ideas, idea]);
    }

    updateIdea(
        attributes: {
            title?: string;
            description?: string;
            isPublic?: boolean;
            mintOnBlockchain?: boolean;
            sendToPrivateVault?: boolean;
        },
        uid: string,
    ) {
        const index = this.ideas.findIndex((idea) => idea.uid === uid);
        if (index !== -1) {
            const ideasCopy = [...this.ideas];
            Object.entries(attributes).forEach(([key, value]) => {
                ideasCopy[index] = {
                    ...ideasCopy[index],
                    [key]: value,
                };
            });
            this.ideasSubject.next(ideasCopy);
        }
    }

    removeIdea(uid: string) {
        const index = this.ideas.findIndex((idea) => idea.uid === uid);

        if (index !== -1) {
            const idea = this.ideas[index];
            if (idea.file) {
                this.filesSize -= idea.file.size;
            }
            const ideasCopy = [...this.ideas];
            ideasCopy.splice(index, 1);
            this.ideasSubject.next(ideasCopy);
            if (idea.progress === 100) {
                sessionStorage.setItem(
                    Token.TEMP,
                    JSON.stringify(
                        this.ideas
                            .filter((idea) => idea.progress === 100)
                            .map((idea) => ({
                                ...idea,
                                preview: undefined,
                            })),
                    ),
                );
            }
        }
    }

    async uploadFiles() {
        try {
            const limit = pLimit(4);
            this.isUploadingSubject.next(true);
            this.ideas.map((idea, index) => {
                this.ideas[index].progress = idea.ideaType === IdeaType.TEXT ? 100 : 0;
            });
            const fileIdeas = this.ideas.filter(
                (idea) => [IdeaType.FILE, IdeaType.IMAGE].indexOf(idea.ideaType) > -1 && !idea.progress,
            );
            const queue = [];
            for (let i = 0; i < fileIdeas.length; i++) {
                const idea = fileIdeas[i];
                queue.push(
                    limit(() =>
                        this.getPresignedUrl(`${idea.uid}.${idea.file?.name.split('.').pop()}`).then((presignedUrl) =>
                            this.uploadFile(idea, presignedUrl),
                        ),
                    ),
                );
            }
            await Promise.all(queue);
            sessionStorage.setItem(
                Token.TEMP,
                JSON.stringify(
                    this.ideas.map((idea) => ({
                        ...idea,
                        preview: undefined,
                    })),
                ),
            );
            this.isUploadingSubject.next(false);
        } catch (error) {
            this.isUploadingSubject.next(false);
        }
    }

    uploadFile(idea: Idea, presignedUrl: string): Promise<boolean> {
        if (!idea.file || !presignedUrl) {
            return new Promise(() => false);
        }
        const req = new HttpRequest('PUT', presignedUrl, idea.file, {
            reportProgress: true,
        });

        return new Promise((resolve, reject) => {
            this.http.request(req).subscribe({
                next: (event: HttpEvent<any>) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        const progress = Math.round((100 * event.loaded) / event.total!);
                        this.ideasSubject.next(
                            this.ideas.map((i) =>
                                i.uid === idea.uid
                                    ? {
                                          ...idea,
                                          progress,
                                      }
                                    : i,
                            ),
                        );
                    } else if (event.type === HttpEventType.Response) {
                        resolve(true);
                    }
                },
                error: (error) => reject(error),
            });
        });
    }

    clearIdeas() {
        sessionStorage.removeItem(Token.TEMP);
        this.ideasSubject.next([]);
    }

    private async getPresignedUrl(filename: string): Promise<string> {
        return firstValueFrom(
            this.http.get<{ url: string }>(environment.apiUrl + Endpoint.PRESIGNED_URL, { params: { filename } }),
        ).then((response) => response.url);
    }
}
