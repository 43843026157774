<button class="btn btn-outline-secondary w-100 mb-2" (click)="takePhoto()" [disabled]="isLoading">
    <img class="me-2" width="24" height="24" ngSrc="/assets/icons/photo-camera.svg" />
    Take a photo
    <input id="cameraInput" type="file" accept="image/*" capture="camera" (change)="handleFileInput($event)" />
</button>
<button class="btn btn-outline-secondary w-100 mb-2" (click)="enterText()" [disabled]="isLoading">
    <img class="me-2" width="24" height="24" ngSrc="/assets/icons/pencil.svg" />Enter text
</button>
<button class="btn btn-outline-secondary w-100 mb-2" (click)="uploadFile()" [disabled]="isLoading">
    <img class="me-2" width="24" height="24" ngSrc="/assets/icons/upload.svg" />Upload a file
    <input id="fileInput" type="file" (change)="handleFileInput($event)" multiple="multiple" />
</button>
