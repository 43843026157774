import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@/app/services';
import { Subject, takeUntil, first, take } from 'rxjs';
import { User } from '@/app/models';
import { NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EmailChangeComponent } from '@/app/components/email-change/email-change.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { TwoFactorAuthSettingsComponent } from '@/app/components/two-factor-auth-settings/two-factor-auth-settings.component';

@Component({
    selector: 'app-security',
    standalone: true,
    imports: [ReactiveFormsModule, NgIf, MatSlideToggle, TwoFactorAuthSettingsComponent],
    templateUrl: './security.component.html',
    styleUrl: './security.component.scss',
})
export class SecurityComponent {
    private ngUnsubscribe = new Subject<void>();
    public isLoading = false;
    public isEmailChanged = false;
    public isEmailChangeConfirmed = false;
    public isTokenInvalid = false;
    public user: User | null = null;
    public error = '';

    constructor(
        private userService: UserService,
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
        });
        const emailConfirmation = this.route.snapshot.queryParamMap.get('email-confirmation');
        if (emailConfirmation) {
            this.userService
                .confirmEmailChange(emailConfirmation)
                .pipe(first())
                .subscribe({
                    next: () => {
                        this.userService.getUser().pipe(take(1)).subscribe();
                        this.isEmailChangeConfirmed = true;
                    },
                    error: () => {
                        this.isTokenInvalid = true;
                    },
                });
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {},
            });
        }
    }

    changeEmail() {
        this.dialog.open(EmailChangeComponent, {
            width: '600px',
        });
    }
}
