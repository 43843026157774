<h2 mat-dialog-title class="p-4">Transfer to your private wallet</h2>
<ng-container *ngIf="user && user.isEnterprise && user.enable2FA">
    <mat-dialog-content>
        <div class="alert alert-danger mb-4" role="alert" *ngIf="error">
            {{ error }}
        </div>
        <form [formGroup]="form" class="mb-2 p-0 p-md-4">
            <div class="mb-4">
                <p>
                    <a
                        class="note"
                        style="text-decoration: none"
                        href="https://www.instantip.today/transfer"
                        target="_blank"
                    >
                        Protected IP transfer feature is for advanced blockchain users only. Read more
                        <img
                            src="/assets/icons/redirect-icon.png"
                            width="12"
                            height="12"
                            style="margin: 2px; margin-left: 5px"
                            priority
                        />
                    </a>
                </p>
                <label for="privateVault" class="form-label">Wallet address</label>
                <input
                    class="form-control form-control-lg"
                    type="text"
                    required
                    placeholder="Polygon wallet address ..."
                    [formControl]="form.controls.privateVault"
                    id="privateVault"
                />
            </div>
            <div class="mb-3">
                <label for="saveToProfile">
                    <input
                        type="checkbox"
                        value="saveToProfile"
                        id="saveToProfile"
                        [formControl]="form.controls.saveToProfile"
                    />
                    <span class="ms-2">Save this address to your profile</span>
                </label>
            </div>
            <div class="mb-3">
                <label for="confirm">
                    <input
                        type="checkbox"
                        value="confirm"
                        id="confirm"
                        [formControl]="form.controls.confirm"
                        required
                    />
                    <strong class="ms-2">Confirm that you have read and understood the meaning of the transfer</strong>
                </label>
            </div>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions class="p-4 px-md-5 pt-0 pb-5" align="end">
        <button class="btn btn-outline-secondary me-2" [disabled]="isLoading" (click)="onCancel()">
            <span>Cancel</span>
        </button>
        <button class="btn btn-primary" [disabled]="!form.valid || isLoading" (click)="onSubmit()">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm d-flex"></span>
            <span *ngIf="!isLoading">Transfer</span>
        </button>
    </div>
</ng-container>
<ng-container *ngIf="user && !user.isEnterprise">
    <mat-dialog-content>
        <div class="p-5 text-center">
            Transfer feature is available only for Enterprise users with two-factor authentication enabled.
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions class="p-4 px-md-5 pt-0 pb-5" align="end">
        <a class="btn btn-outline-secondary me-2" href="https://www.instantip.today/join-enterprise/">Read More</a>
        <button class="btn btn-primary" (click)="onCancel()">Close</button>
    </div>
</ng-container>
<ng-container *ngIf="user && user.isEnterprise && !user.enable2FA">
    <mat-dialog-content>
        <div class="p-5 text-center">Transfer feature is available only for users with two-factor authentication enabled.</div>
    </mat-dialog-content>
    <div mat-dialog-actions class="p-4 px-md-5 pt-0 pb-5" align="end">
        <a class="btn btn-outline-secondary me-2" [routerLink]="['/security']" (click)="onCancel()">Enable Two-Factor Authentication</a>
        <button class="btn btn-primary" (click)="onCancel()">Close</button>
    </div>
</ng-container>
