<div class="card-body justify-content-center p-4">
    <ul
        class="list"
        *ngIf="transactions.length"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="'.mat-drawer-content'"
        [scrollWindow]="false"
        [fromRoot]="true"
        (scrolled)="loadMore()"
        [alwaysCallback]="true"
        [infiniteScrollDisabled]="!pagination || pagination.page === pagination.pageCount"
    >
        <li *ngFor="let transaction of transactions" class="d-flex align-items-center transaction">
            <span class="d-block w-100">
                <strong class="d-flex justify-content-between">
                    <span class="d-flex">{{ transaction.attributes.type }}</span>
                    <span
                        class="d-flex"
                        [ngClass]="{
                            'text-success': transaction.attributes.type === TransactionType.DEPOSIT,
                            'text-danger': transaction.attributes.type === TransactionType.CHARGE
                        }"
                    >
                        <span *ngIf="transaction.attributes.type === TransactionType.CHARGE">-</span>
                        <span *ngIf="transaction.attributes.type === TransactionType.DEPOSIT">+</span>
                        {{ transaction.attributes.amount }}
                    </span>
                </strong>
                <small class="opacity-50">{{ transaction.attributes.createdAt | date: 'medium' }}</small>
                <span class="d-block mt-3" *ngIf="transaction.attributes.ip?.data"
                    ><a [routerLink]="['/ideas', transaction.attributes.ip?.data?.id]">{{
                        transaction.attributes.ip?.data?.attributes?.title
                    }}</a></span
                >
            </span>
        </li>
    </ul>
    <div *ngIf="isLoading" class="p-5 my-5 d-flex justify-content-center">
        <div class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>
