<div class="card-body justify-content-center p-4">
    <h1>Remind Password</h1>
    <div id="form-wrapper">
        <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
        <div *ngIf="submitted" class="alert alert-success">
            If the email address you provided is associated with an account in our system, we have sent a password reset link to that address. Please check your email inbox and follow the instructions.

        </div>
        <form id="sign-in-form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mb-3">
                <label class="form-label" for="email">Email address</label>
                <input
                    class="form-control form-control-lg"
                    id="email"
                    type="text"
                    formControlName="email"
                    [ngClass]="{
                        'is-invalid': form.controls.email.touched && form.controls.email.errors
                    }"
                    required
                />
                <div class="invalid-feedback" *ngIf="form.controls.email.hasError('required')">Required field</div>
                <div class="invalid-feedback" *ngIf="form.controls.email.hasError('email')">
                    Incorrect email address
                </div>
            </div>
            <button class="btn btn-primary w-100 mt-5 mb-3" type="submit" [disabled]="isLoading || !form.valid">
                <span *ngIf="isLoading" class="spinner-border">
                    <span class="visually-hidden">Loading...</span>
                </span>
                <span *ngIf="!isLoading">Submit</span>
            </button>
        </form>
    </div>
</div>
<div class="card-footer pb-5 pt-2 text-center">
    <a [routerLink]="['/sign-in']">Sign In</a>
</div>
