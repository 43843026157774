import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import qs from 'qs';

import { environment } from '@/environments/environment';
import { Endpoint } from '@/enums';
import {ResponseWrapper, StrapiQs} from '@/types';
import { Transaction } from '@/app/models';

@Injectable({ providedIn: 'root' })
export class TransactionService {
    constructor(private http: HttpClient) {}

    getTransactions(params?: {
        page?: number;
    }): Observable<ResponseWrapper<Transaction[]>> {

        const searchParams: StrapiQs = {
            sort: ['createdAt:desc'],
            pagination: {
                page: params?.page || 1
            },
            populate: 'ip'
        };

        return this.http.get<ResponseWrapper<Transaction[]>>(
            `${environment.apiUrl + Endpoint.TRANSACTIONS}?${qs.stringify(searchParams)}`,
            {
                withCredentials: true,
            },
        )
    }

}
