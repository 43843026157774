<h2 mat-dialog-title class="p-4">Change Email</h2>
<ng-container *ngIf="isEmailChangeRequested">
    <mat-dialog-content>
        <div class="pb-4">
            <p>
                Please check your email inbox for a verification message. Follow the instructions in the email to verify
                your email address. If you don’t see the email, make sure to check your spam or junk folder.
            </p>
            <p>If you need any help, <a href="">please contact</a> our support team for assistance.</p>
        </div>
        <div mat-dialog-actions class="p-4 px-md-5 pt-0 pb-5" align="end">
            <button class="btn btn-primary" (click)="closeDialog()">
                Close
            </button>
        </div>        
    </mat-dialog-content>
</ng-container>
<ng-container *ngIf="!isEmailChangeRequested">
    <mat-dialog-content>
        <p class="mb-5">
            Please enter your new email address. We will send a verification link to this address. The new email will
            only become active after it has been verified.
        </p>
        <form [formGroup]="form">
            <label for="email" class="form-label">New Email Address</label>
            <input
                class="form-control form-control-lg mb-3"
                type="text"
                formControlName="email"
                placeholder="Email"
                id="email"
                required
            />
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions class="p-4 pt-0 pb-5" align="end">
        <button class="btn btn-primary w-100 align-self-end" [disabled]="isLoading" (click)="submit()">
            <div *ngIf="isLoading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <ng-container *ngIf="!isLoading">Send Verification Link</ng-container>
        </button>
        <button class="btn btn-outline-secondary w-100 align-self-end" style="margin-top: 5px;" (click)="closeDialog()">
            Cancel
        </button>
</div>
</ng-container>
