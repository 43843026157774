import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import qs from 'qs';

import { environment } from '@/environments/environment';
import { Endpoint } from '@/enums';
import {ResponseWrapper, StrapiQs} from '@/types';
import { Order } from '@/app/models';
import {map} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OrderService {
    constructor(private http: HttpClient) {}

    makeOrder(data: any): Observable<any> {
        return this.http.post<any>(environment.apiUrl + Endpoint.ORDERS, data, {
            withCredentials: true,
        });
    }

    getOrders(params?: {
        page?: number;
    }): Observable<ResponseWrapper<Order[]>> {

        const searchParams: StrapiQs = {
            sort: ['createdAt:desc'],
            pagination: {
                page: params?.page || 1
            },
            populate: 'item'
        };

        return this.http.get<ResponseWrapper<Order[]>>(
            `${environment.apiUrl + Endpoint.ORDERS}?${qs.stringify(searchParams)}`,
            {
                withCredentials: true,
            },
        );
    }

    getOrder(id: number): Observable<Order> {
        return this.http.get<ResponseWrapper<Order>>(
            `${environment.apiUrl + Endpoint.ORDERS}/${id}?populate=item`,
            {
                withCredentials: true,
            },
        ).pipe(map((response) => response.data));
    }

    getStripeSession(id: number, fresh?: boolean): Observable<any> {

        if (!fresh)
            fresh = false;

        return this.http.get<any>(
            `${environment.apiUrl + Endpoint.ORDERS}/${id}/stripe-session?fresh=${fresh}`,
            {
                withCredentials: true,
            },
        );
    }
}
