
<ng-container *ngIf="!user?.isEnterprise">

    <mat-slide-toggle
        name="enable2FA"
        formControlName="enable2FA"
        [disableRipple]="true"
        class="mt-1 mb-2"
        color="primary"
        [disabled]="true"
    >
        Enable Two-Factor Authentication
    </mat-slide-toggle>
    <div class="mt-4">
        <a class="note" style="text-decoration: none;" href="https://www.instantip.today/enterprise" target="_blank">
            Two-Factor Authentication is available for Enterprise users only. Read more
            <img src="/assets/icons/redirect-icon.png" width="12" height="12" style="margin: 2px; margin-left: 5px;" priority />
        </a>        
    </div>

</ng-container>
<ng-container *ngIf="user?.isEnterprise">

<div *ngIf="is2FASaved" class="alert alert-success">Two-Factor Authentication settings successfully changed.</div>
<form [formGroup]="form" class="inline-edit my-4">
    <mat-slide-toggle
        name="enable2FA"
        formControlName="enable2FA"
        [disableRipple]="true"
        class="mt-1 mb-2"
        color="primary"
        [disabled]="user?.isEnterprise ? false : true"
        [checked]="user?.enable2FA"
        (change)="toggle2FA($event)"
    >
        Enable Two-Factor Authentication
    </mat-slide-toggle>

    <div *ngIf="showPhoneInput" class="mt-4">
        <label for="phone" class="form-label d-block">Your Phone Number</label>
        <ngx-intl-tel-input
            [cssClass]="'form-control form-control-lg'"
            [preferredCountries]="[CountryISO.UnitedStates]"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="false"
            [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
            [selectFirstCountry]="true"
            [maxLength]="15"
            [phoneValidation]="true"
            [numberFormat]="PhoneNumberFormat.National"
            (keydown)="changed2FA = true"
            name="phone"
            id="phone"
            formControlName="phone"
            required
        >
        </ngx-intl-tel-input>        
        <div class="invalid-feedback mb-2" *ngIf="form.controls.phone.hasError('required')">Cannot be empty</div>
        <div class="mt-4 note" *ngIf="!form.controls.phone.value || (form.invalid && form.controls.enable2FA.value)">
            Please enter your phone number to set up two-factor authentication. A verification code will be sent to your phone.
        </div>
    </div>
    <button
        *ngIf="changed2FA"
        class="btn btn-primary w-100 my-4 align-self-end"
        (click)="submit2FA()"
        [disabled]="(form.invalid && form.controls.enable2FA.value) || isLoading"
    >
        <span *ngIf="isLoading" class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </span>
        <span *ngIf="!isLoading && !form.controls.enable2FA.value">Save Changes</span>
        <span *ngIf="!isLoading && form.controls.enable2FA.value">Send Verification Code</span>
    </button>
</form>

</ng-container>
