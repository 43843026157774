<div style="position: absolute; top: -9999px; left: -9999px">
    <div id="pdfContent" style="font-family: Arial, sans-serif; padding: 100px; width: 950px !important;" *ngIf="ip">
        <div class="d-flex justify-content-center py-4">
            <img ngSrc="/assets/logo_black.png" width="200" height="163" priority style="width: 200px !important; height: 163px !important;" />
        </div>
        <h2>Protected IP Certificate</h2>
        <p><strong>Idea:</strong> {{ ip.attributes.title }} ᴵᴾ</p>
        <p><strong>Created At:</strong> {{ ip.attributes.createdAt | date: 'medium':'ET' }} Eastern Time</p>
        <p><strong>Description:</strong> {{ ip.attributes.description }}</p>
        <ng-container *ngIf="ip.attributes.NFTAddress">
            <p><strong>Proof of Creation (fingerprint):</strong> {{ ip.attributes.checksum }}</p>
            <p><strong>Minted on Blockchain:</strong> {{ ip.attributes.NFTAddress }}</p>
        </ng-container>
        <ng-container *ngIf="!ip.attributes.NFTAddress && ip.attributes.protectedUrl">
            <p><strong>Minted on Blockchain:</strong> {{ ip.attributes.protectedUrl }}</p>
        </ng-container>
        <br />&nbsp;
        <p>
            Congratulations. Your IP is Now Protected. As a result, you have the exclusive right to display<br />
            the Official The Instant IP Certification Mark ᴵᴾ whenever using your respective expression of IP.<br />
            This Certification Mark usage is granted to the creator indefinitely however, only for each unique<br />
            IP expression which is protected via Instant IP™. For more information, please reference the official<br />
            statement below.
        </p>
        <p>
            The superscript symbol ᴵᴾ listed is known as the unique certification mark created and owned by <br />
            Instant IP™. Its use signifies that the corresponding expression (words, phrases, chart, graph, etc.)<br />
            has been protected by Instant IP™ via smart contract. Instant IP™ is designed with the patented <br />
            smart contract solution (US Patent: 11,928,748) which creates an immutable time-stamped first <br />
            layer and fast layer identifying the moment in time an idea is filed on the blockchain. This<br />
            solution can be used in defending intellectual property protection. Infringing upon the respective <br />
            intellectual property i.e. ᴵᴾ is subject to and punishable in a court of law. Please see Terms and Conditions <br />
            for additional information. <br />
        </p>
    </div>
</div>
<button *ngIf="ip && ip.attributes.NFTAddress" 
    [disabled]="!ip.attributes.protectedUrl"
    class="btn btn-outline-secondary w-100 mt-2 mb-2" (click)="downloadPdf(ip.attributes.title)">
    Download Protected IP Certificate
</button>
