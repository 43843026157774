import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@/environments/environment';
import { Product } from '@/app/models';
import { Endpoint } from '@/enums';
import { ResponseWrapper } from '@/types';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductService {
    constructor(private http: HttpClient) {}

    getProducts(): Observable<Product[]> {
        return this.http
            .get<ResponseWrapper<Product[]>>(environment.apiUrl + Endpoint.PRODUCTS + '?sort=credits', {
                withCredentials: true,
            })
            .pipe(map((response) => response.data));
    }

    getProduct(id: number): Observable<Product> {
        return this.http
            .get<ResponseWrapper<Product>>(`${environment.apiUrl + Endpoint.PRODUCTS}/${id}`, {
                withCredentials: true,
            })
            .pipe(map((response) => response.data));
    }
}
