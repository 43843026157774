<div class="card-body justify-content-center p-4">
    <div *ngIf="isLoading.ip" class="p-5 my-5 d-flex justify-content-center">
        <div class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <a
        *ngIf="!isLoading.ip && ip && showBack"
        class="back-link"
        [routerLink]="['/protected/companies', ip.attributes.user.data.attributes.slug || ip.attributes.user.data.id]"
        ><img ngSrc="/assets/icons/back.svg" class="me-2" width="24" height="24" />Back to&nbsp;<strong>{{
            ip.attributes.user.data.attributes.fullName
        }}</strong></a
    >
    <a *ngIf="!isLoading.ip && ip && !showBack" class="back-link" [routerLink]="['/protected']"
        ><img ngSrc="/assets/icons/back.svg" class="me-2" width="24" height="24" />Back to all Protected IP</a
    >
    <ng-container *ngIf="!isLoading.ip && ip">
        <div class="d-flex idea">
            <div class="thumbnail me-3">
                <mat-icon
                    *ngIf="!ip.attributes.thumbnail && ip.attributes.type === IdeaType.FILE.toString()"
                    fontIcon="insert_drive_file"
                    class="overflow-visible"
                />
                <mat-icon
                    *ngIf="!ip.attributes.thumbnail && ip.attributes.type === IdeaType.TEXT.toString()"
                    fontIcon="article"
                    class="overflow-visible"
                />
                <img
                    *ngIf="ip.attributes.thumbnail"
                    [src]="ip.attributes.thumbnail!"
                    onerror="this.style.display='none'"
                />
            </div>
            <div class="flex-grow-1">
                <div class="text-break">
                    <strong class="d-block">{{ ip.attributes.title }}</strong>
                    <p>{{ ip.attributes.description }}</p>
                    <small class="opacity-50">{{ ip.attributes.createdAt | date: 'medium' }}</small>
                </div>
            </div>
        </div>
        <p *ngIf="ip.attributes.content" class="my-5">
            {{ ip.attributes.content }}
        </p>

        <button
            class="btn btn-outline-secondary w-80 mt-5 mb-2"
            (click)="downloadIp()"
            [disabled]="isLoading.presignedUrl"
        >
            <span *ngIf="isLoading.presignedUrl" class="spinner-border">
                <span class="visually-hidden">Loading...</span>
            </span>
            <span *ngIf="!isLoading.presignedUrl">Download IP</span>
        </button>
        <button
            class="btn btn-primary w-80 mb-3"
            [disabled]="!ip.attributes.protectedUrl"
            (click)="downloadProtected()"
        >
            View Protected IP
        </button>
    </ng-container>
</div>
