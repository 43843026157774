import { Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { IpService, UserService } from '@/app/services';
import { Subject, takeUntil } from 'rxjs';
import { Ip, User } from '@/app/models';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { IdeaType, IpType } from '@/enums';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ResponseWrapper, PaginationMeta } from '@/types';
import { MatButton, MatMiniFabButton } from '@angular/material/button';

@Component({
    selector: 'app-ideas-list',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIcon,
        MatMiniFabButton,
        MatButton,
        RouterLink,
        InfiniteScrollModule,
    ],
    templateUrl: './ideas-list.component.html',
    styleUrl: './ideas-list.component.scss',
})
export class IdeasListComponent {
    @Input() orderId?: number;
    @Input() showEmptyInfo?: boolean;
    @Input() showPrivate?: boolean;
    public ips: Ip[] = [];
    private ngUnsubscribe = new Subject<void>();
    public isLoading: boolean = true;
    public pagination: PaginationMeta | null = null;
    public user: User | null = null;
    IpType = IpType;
    isPublicRoute: boolean = false;
    hasReloaded = 0;

    constructor(
        private router: Router,
        private ipService: IpService,
        private userService: UserService,
    ) {}

    loadIps() {
        this.isLoading = true;
        this.ipService
            .getIps({
                page: this.pagination?.page,
                orderId: this.orderId,
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: ResponseWrapper<Ip[]>) => {
                    const { data, meta } = response;
                    let ipsBuffer = [...data];
                    this.pagination = meta.pagination!;

                    if (this.hasReloaded < 2 && ipsBuffer.length > 0) {
                        this.hasReloaded = this.hasReloaded + 1;
                        const firstIp = ipsBuffer[0];
                        const createdAt = new Date(firstIp.attributes.createdAt);
                        let updatedAt = new Date(firstIp.attributes.updatedAt);
                        const now = new Date();

                        if (!updatedAt) updatedAt = createdAt;

                        const updatedDifferenceInSeconds = (now.getTime() - updatedAt.getTime()) / 1000;
                        const createdDifferenceInSeconds = (now.getTime() - createdAt.getTime()) / 1000;

                        // If updated within last two minutes and protection is pending, let's reload
                        if (
                            (updatedDifferenceInSeconds < 120 || createdDifferenceInSeconds < 120) &&
                            firstIp.attributes.status !== 'PROTECTED'
                        ) {
                            this.ips = [];
                            setTimeout(() => {
                                this.loadIps();
                            }, 7000);
                        } else {
                            this.ips = [...this.ips, ...ipsBuffer];
                            this.isLoading = false;
                        }
                    } else {
                        this.ips = [...this.ips, ...ipsBuffer];
                        this.isLoading = false;
                    }
                },
                error: () => {
                    this.ips = [];
                    this.isLoading = false;
                },
            });
    }

    ngOnInit() {
        this.isPublicRoute = this.router.url === '/ideas/public';
        this.loadIps();
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
        });
    }

    openPublicWebsite() {
        window.open('/protected/companies/' + this.user?.id, '_blank');
    }

    loadMore() {
        if (!this.pagination) {
            return;
        }
        this.pagination.page += 1;
        this.loadIps();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly IdeaType = IdeaType;
}
