<div class="p-5">
    <div class="d-flex text-start align-items-center justify-content-between pt-3 pb-4 mb-3">
        <h1 class="m-0">Profile and cover images</h1>
    </div>
    <div>
        Your profile and cover picture will be visible in your public view, as well as on platforms like OpenSea, and
        others.
    </div>
    <div class="mt-4">
        <label for="profileUpload" class="form-label">Your Profile</label>
        <input
            style="max-width: 100%; white-space: break-spaces"
            type="file"
            required
            id="profileUpload"
            (change)="changeProfile($event)"
            accept="image/png,image/svg+xml,image/jpg,image/gif"
        />
        <span class="d-block mt-2 text-secondary"
            >For best results, upload a JPG or PNG that's approximately 400 x 400 pixels (max 2MB)</span
        >
    </div>

    <div class="mt-4">
        <label for="coverUpload" class="form-label">Your Cover</label>
        <input
            style="max-width: 100%; white-space: break-spaces"
            type="file"
            required
            id="coverUpload"
            (change)="changeCover($event)"
            accept="image/png,image/svg+xml,image/jpg,image/gif"
        />
        <span class="d-block mt-2 text-secondary"
            >For best results, upload a JPG or PNG that's approximately 1584 x 396 pixels (max 5MB)</span
        >
    </div>

    <button class="btn btn-primary w-100 mt-5 align-self-end" (click)="back()">
        <ng-container>Upload</ng-container>
    </button>
</div>
