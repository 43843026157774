import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterLink, RouterLinkActive} from '@angular/router';

@Component({
    selector: 'app-showroom-nav',
    standalone: true,
    imports: [CommonModule, RouterLink, RouterLinkActive],
    templateUrl: './showroom-nav.component.html',
    styleUrl: './showroom-nav.component.scss',
})
export class ShowroomNavComponent {}
