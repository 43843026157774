<ul class="nav nav-tabs">
    <li class="nav-item">
        <a
            class="nav-link"
            [routerLink]="['/ideas']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Private View</a
        >
    </li>
    <li class="nav-item">
        <a
            class="nav-link"
            [routerLink]="['/ideas/public']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Public View</a
        >
    </li>
</ul>
