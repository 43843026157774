import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { AuthService } from '@/app/services';
import { first } from 'rxjs';
import { ApiError } from '@/types';
import {InvalidTokenComponent} from '@/app/components/invalid-token/invalid-token.component';
import {VerifyCodeComponent} from '@/app/components/verify-code/verify-code.component';

@Component({
    selector: 'app-sign-in',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        RouterLink,
        NgIf,
        NgClass,
        InvalidTokenComponent,
        VerifyCodeComponent,
    ],
    templateUrl: './sign-in.component.html',
    styleUrl: './sign-in.component.scss',
})
export class SignInComponent {
    isLoading = false;
    isTokenInvalid = false;
    error = '';
    form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
    });
    showPassword = false;
    returnUrl: string = '';
    show2FA = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        const confirmation = this.route.snapshot.queryParamMap.get('confirmation');
        if (confirmation) {
            this.authService
                .confirm(confirmation)
                .pipe(first())
                .subscribe({
                    next: () => {
                        this.router.navigate(['/upload-idea']);
                    },
                    error: () => {
                        this.isTokenInvalid = true;
                    },
                });
        }
        if (this.authService.token) {
            this.router.navigate(['/ideas']);
        }
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/ideas';
    }

    onSubmit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.isLoading = true;
        this.authService
            .login({
                identifier: this.form.controls.email.value!,
                password: this.form.controls.password.value!,
            })
            .pipe(first())
            .subscribe({
                next: (response) => {
                    if (response.codeSent) {
                        this.show2FA = true;
                    } else {
                        this.router.navigate([this.returnUrl]);
                    }
                    this.isLoading = false;
                },
                error: ({ error }: { error: ApiError }) => {
                    if (error?.error?.name === 'ApplicationError' && error?.error?.status === 400) {
                        this.error =
                            'Your account email is not confirmed, please check your email inbox for a confirmation message.';
                    } else {
                        this.error = error?.error?.message || JSON.stringify(error);
                    }

                    this.isLoading = false;
                },
            });
    }

    cancel2FA() {
        this.show2FA = false;
        this.form.reset();
    }

    onEyeClicked() {
        this.showPassword = !this.showPassword;
    }
}
