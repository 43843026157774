<h2 mat-dialog-title class="p-4">Confirm making Idea public</h2>
<mat-dialog-content>
    <div class="mb-2 p-0 p-md-4">
        <small class="d-block"
            ><strong class="text-danger">WARNING</strong><br />
            A trade secret can lose its protected status if it is disclosed to the public, either intentionally or
            accidentally.
        </small>
        <small class="d-block mt-3"
            ><strong>Disclaimer</strong><br />
            The information you obtain at this site is not, nor is it intended to be, legal advice.</small
        >
        <form [formGroup]="form" class="mt-5">
            <label for="confirm" class="d-flex">
                <input
                    type="checkbox"
                    value="confirm"
                    id="confirm"
                    [formControl]="form.controls.confirm"
                    required
                />
                <strong class="ms-3 d-block"
                >By making my idea public, I acknowledge that any trade secret status and/or protection, may be 
                    lost upon public disclosure.</strong
                >
            </label>
        </form>
    </div>
</mat-dialog-content>
<div mat-dialog-actions class="p-4 px-md-5 pt-0 pb-5" align="end">
    <button class="btn btn-outline-secondary me-2" (click)="onCancel()">
        <span>Cancel</span>
    </button>
    <button class="btn btn-primary" [disabled]="!form.valid" (click)="onSubmit()">Submit</button>
</div>
