import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { IpService, UserService } from '@/app/services';
import { User } from '@/app/models';

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MatDialogModule, NgOptimizedImage],
    selector: 'app-transfer',
    templateUrl: './transfer.component.html',
    styleUrl: './transfer.component.scss',
})
export class TransferComponent {
    public user: User | null = null;
    readonly form = new FormGroup({
        privateVault: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required],
        }),
        saveToProfile: new FormControl<boolean>(false),
        confirm: new FormControl<boolean>(false, {
            nonNullable: true,
            validators: [Validators.required],
        }),
    });
    public isLoading = false;
    public error: string | null = null;
    private ngUnsubscribe = new Subject<void>();
    constructor(
        public dialogRef: MatDialogRef<TransferComponent>,
        private userService: UserService,
        private ipService: IpService,
        @Inject(MAT_DIALOG_DATA) public data: { ipId: string },
    ) {}

    ngOnInit() {
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
            this.form.controls.privateVault.setValue(this.user?.privateVault || '');
        });
    }

    async onSubmit() {
        if (this.form.invalid) {
            return;
        }
        this.isLoading = true;
                        
        await lastValueFrom(
            this.ipService.transferToWallet(
                parseInt(this.data.ipId!),
                this.form.controls.privateVault.getRawValue(),
                this.form.controls.saveToProfile.getRawValue() || false,
            ),
        ).then((response) => {
            this.isLoading = false;
            this.dialogRef.close();
        })
        .catch((error) => {
            this.isLoading = false;
            console.log('error', error);
        });
    }

    onCancel() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
