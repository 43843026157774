import { Component } from '@angular/core';
import {ActivatedRoute, RouterLink} from '@angular/router';
import { OrderService, UserService } from '@/app/services';
import { Subject, takeUntil } from 'rxjs';
import {Transaction, User} from '@/app/models';
import { CommonModule } from '@angular/common';
import { IdeasListComponent } from '@/app/components/ideas-list/ideas-list.component';
import {TransactionService} from '@/app/services/transaction.service';
import {PaginationMeta, ResponseWrapper} from '@/types';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {TransactionType} from '@/enums';

@Component({
    selector: 'app-wallet',
    standalone: true,
    imports: [CommonModule, IdeasListComponent, RouterLink, InfiniteScrollModule],
    templateUrl: './wallet.component.html',
    styleUrl: './wallet.component.scss',
})
export class WalletComponent {
    private ngUnsubscribe = new Subject<void>();
    public isLoading: boolean = false;
    public user: User | null = null;
    public pagination: PaginationMeta | null = null;
    public transactions: Transaction[] = [];
    constructor(
        private userService: UserService,
        private transactionService: TransactionService,
    ) {}

    loadTransactions() {
        this.isLoading = true;
        this.transactionService
            .getTransactions({
                page: this.pagination?.page,
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: ResponseWrapper<Transaction[]>) => {
                    const { data, meta } = response;
                    this.transactions = [
                        ...this.transactions,
                        ...data,
                    ];
                    this.pagination = meta.pagination!;
                    this.isLoading = false;
                },
                error: () => {
                    this.transactions = [];
                    this.isLoading = false;
                },
            });
    }

    loadMore() {
        if (!this.pagination) {
            return;
        }
        this.pagination.page += 1;
        this.loadTransactions();
    }

    ngOnInit() {
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
        });
        this.loadTransactions();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly TransactionType = TransactionType;
}
