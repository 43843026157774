import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { UploadIdeaComponent } from './components/upload-idea/upload-idea.component';
import { IdeasComponent } from './components/ideas/ideas.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { RemindPasswordComponent } from './components/remind-password/remind-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { IdeaDetailsComponent } from './components/idea-details/idea-details.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { TermsComponent } from '@/app/components/terms/terms.component';
import { AuthGuard } from '@/app/guards';
import { PublicIdeasComponent } from '@/app/components/public-ideas/public-ideas.component';
import { PublicIdeaDetailsComponent } from '@/app/components/public-idea-details/public-idea-details.component';
import { PublicIdeaCollectionsComponent } from '@/app/components/public-idea-collections/public-idea-collections.component';
import { PublicIdeaCollectionDetailsComponent } from '@/app/components/public-idea-collection-details/public-idea-collection-details.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { BillingComponent } from './components/billing/billing.component';
import { PublicViewIdeasComponent } from './components/public-view-ideas/public-view-ideas.component';
import { SecurityComponent } from '@/app/components/security/security.component';

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'sign-in',
        component: SignInComponent,
    },
    {
        path: 'sign-up',
        component: SignUpComponent,
    },
    {
        path: 'enterprise-sign-up',
        component: SignUpComponent,
    },
    {
        path: 'remind-password',
        component: RemindPasswordComponent,
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'upload-idea',
        component: UploadIdeaComponent,
    },
    {
        path: 'terms',
        component: TermsComponent,
    },
    {
        path: 'protected',
        component: PublicIdeasComponent,
    },
    {
        path: 'protected/companies',
        component: PublicIdeaCollectionsComponent,
    },
    {
        path: 'protected/companies/:id',
        component: PublicIdeaCollectionDetailsComponent,
    },
    {
        path: 'protected/:id',
        component: PublicIdeaDetailsComponent,
    },
    {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'ideas',
        component: IdeasComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'ideas/public',
        component: PublicViewIdeasComponent,
    },
    {
        path: 'ideas/:id',
        component: IdeaDetailsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [AuthGuard],
        outlet: 'tab',
    },
    {
        path: 'orders/:id',
        component: OrderDetailsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'wallet',
        component: WalletComponent,
        canActivate: [AuthGuard],
        outlet: 'tab',
    },
    {
        path: 'billing',
        component: BillingComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'credits', component: WalletComponent },
            { path: 'orders', component: OrdersComponent },
        ],
    },
    {
        path: 'user-settings',
        component: UserSettingsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'security',
        component: SecurityComponent,
        canActivate: [AuthGuard],
    },
    { path: '**', redirectTo: '' },
];
