<div class="card-body justify-content-center p-4">
    <a class="back-link" [routerLink]="['/ideas']"
        ><img src="/assets/icons/back.svg" class="me-2" width="24" height="24" />Back to all Protected IP</a
    ><br />&nbsp;
    <div *ngIf="isLoading.ip" class="p-5 my-5 d-flex justify-content-center">
        <div class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <ng-container *ngIf="!isLoading.ip && ip">
        <div class="d-flex idea">
            <div class="thumbnail me-3">
                <mat-icon
                    *ngIf="ip.attributes.type === IdeaType.TEXT.toString() || ip.attributes.status !== 'PROTECTED'"
                    fontIcon="article"
                    class="overflow-visible"
                />
                <img
                    *ngIf="ip.attributes.status === 'PROTECTED' && ip.attributes.type !== IdeaType.TEXT.toString()"
                    [src]="ip.attributes.thumbnail!"
                    onerror="this.style.display='none'"
                />
            </div>
            <div class="flex-grow-1">
                <span
                    class="badge rounded-pill mb-1"
                    [ngClass]="{
                        'text-bg-primary': ip.attributes.status === 'PROTECTED',
                        'text-bg-info': ip.attributes.status === 'PROTECTION_PENDING',
                        'text-bg-success': ip.attributes.status === 'PAID',
                        'text-bg-warning': ip.attributes.status === 'PAYMENT_PENDING',
                        'text-bg-danger': ip.attributes.status === 'PAYMENT_FAILED'
                    }"
                    >{{ ip.attributes.status.replace('_', ' ') }}</span
                >
                <span *ngIf="ip.attributes.isPublic" class="badge rounded-pill mb-1 text-bg-success">Public</span>
                <span *ngIf="ip.attributes.sendToPrivateVault" class="badge rounded-pill mb-1 text-bg-info"
                    >In Private Wallet</span
                >
                <div class="text-break">
                    <div *ngIf="!editMode">
                        <strong class="d-block">{{ ip.attributes.title }}</strong>
                        <p>{{ ip.attributes.description }}</p>
                    </div>
                    <div class="mb-3" *ngIf="!editMode">
                        <button
                            *ngIf="isEnterprise"
                            mat-stroked-button
                            color="primary"
                            (click)="editMode = true"
                            [disabled]="isLoading.update"
                        >
                            Edit Visibility
                        </button>
                        <button
                            *ngIf="
                                ip.attributes.status === 'PROTECTED' &&
                                !ip.attributes.sendToPrivateVault &&
                                ip.attributes.mintOnBlockchain
                            "
                            class="mx-2"
                            mat-stroked-button
                            color="primary"
                            (click)="transfer()"
                            [disabled]="isLoading.update || !ip.attributes.NFTExternalId"
                        >
                            Transfer to wallet
                        </button>
                    </div>
                    <form [formGroup]="form" *ngIf="editMode" class="inline-edit my-2">
                        <textarea
                            class="form-control mb-2"
                            formControlName="title"
                            name="title"
                            placeholder="Idea Title"
                            autosize
                            [readonly]="true"
                            [ngClass]="{
                                'is-invalid': form.controls.title.touched && form.controls.title.errors
                            }"
                            >{{ ip.attributes.title }}</textarea
                        >
                        <div class="invalid-feedback mb-2" *ngIf="form.controls.title.hasError('required')">
                            Cannot be empty
                        </div>
                        <textarea
                            class="form-control mb-2"
                            formControlName="description"
                            name="description"
                            placeholder="Idea Description"
                            autosize
                            [readonly]="true"
                            [ngClass]="{
                                'is-invalid': form.controls.description.touched && form.controls.description.errors
                            }"
                            >{{ ip.attributes.description }}</textarea
                        >
                        <mat-slide-toggle
                            formControlName="isPublic"
                            name="isPublic"
                            [disableRipple]="true"
                            class="mt-1 mb-2"
                            color="primary"
                            [checked]="ip.attributes.isPublic"
                        >
                            <span *ngIf="!form.controls.isPublic.value">Hidden (Your Idea CAN’T be seen)</span>
                            <span *ngIf="form.controls.isPublic.value">Public (Your Idea CAN be seen)</span>
                        </mat-slide-toggle>
                        <div class="d-flex justify-content-end">
                            <button
                                mat-stroked-button
                                color="primary"
                                (click)="closeEdit()"
                                [disabled]="isLoading.update"
                            >
                                <mat-icon fontIcon="close" />
                            </button>
                            <button
                                mat-flat-button
                                color="primary"
                                class="ms-2"
                                [disabled]="isLoading.update"
                                (click)="updateIp()"
                            >
                                <mat-icon *ngIf="!isLoading.update" fontIcon="check" />
                                <span *ngIf="isLoading.update" class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                            </button>
                        </div>
                    </form>
                    <small class="opacity-50">{{ ip.attributes.createdAt | date: 'medium' }}</small>
                </div>
            </div>
        </div>
        <app-pay-now
            *ngIf="ip.attributes.order.data && IpStatus.PaymentFailed.toString() === ip.attributes.status"
            [orderId]="ip.attributes.order.data.id!"
        />
        <p *ngIf="ip.attributes.content" class="my-5">
            {{ ip.attributes.content }}
        </p>
        <button
            class="btn btn-outline-secondary w-100 mt-5 mb-2"
            (click)="downloadIp()"
            [disabled]="isLoading.presignedUrl || !ip.attributes.protectedUrl"
        >
            <span *ngIf="isLoading.presignedUrl" class="spinner-border">
                <span class="visually-hidden">Loading...</span>
            </span>
            <span *ngIf="!isLoading.presignedUrl">Download Protected IP</span>
        </button>
        <app-pdf-receipt [ip]="ip"></app-pdf-receipt>
        <button
            class="btn btn-primary w-100 mb-3"
            [disabled]="!ip.attributes.protectedUrl"
            (click)="downloadProtected()"
        >
            View Protected IP
        </button>
        <small class="d-block text-center opacity-75" *ngIf="!ip.attributes.protectedUrl"
            >Your IP protection is not ready yet, please come back later or wait for an email notification.</small
        >
        <button
            *ngIf="ip.attributes.status !== 'PROTECTED'"
            class="btn btn-link text-danger mx-auto mt-4 mb-2"
            (click)="deleteIp()"
        >
            <span *ngIf="isLoading.delete" class="spinner-border">
                <span class="visually-hidden">Loading...</span>
            </span>
            <span *ngIf="!isLoading.delete">Delete Idea</span>
        </button>
    </ng-container>
</div>
