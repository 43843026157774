import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { AuthService } from '@/app/services';
import { first } from 'rxjs';
import { ApiError } from '@/types';

@Component({
    selector: 'app-invalid-token',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgOptimizedImage, RouterLink, NgIf, NgClass],
    templateUrl: './invalid-token.component.html',
    styleUrl: './invalid-token.component.scss',
})
export class InvalidTokenComponent {
    isLoading = false;
    isSent = false;
    error = '';
    form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
    });

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
    ) {}

    onSubmit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.isLoading = true;
        this.authService
            .resendConfirmation(this.form.controls.email.value!)
            .pipe(first())
            .subscribe({
                next: (response) => {
                    this.isLoading = false;
                    this.isSent = response.sent;
                },
                error: ({ error }: { error: ApiError }) => {
                    console.log(error);
                    this.isLoading = false;
                },
            });
    }
}
