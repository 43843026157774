import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { OrderService } from '@/app/services';
import { Subject, takeUntil } from 'rxjs';
import { Order } from '@/app/models';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatFabButton, MatMiniFabButton } from '@angular/material/button';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ResponseWrapper, PaginationMeta } from '@/types';
import {OrderStatus} from '@/enums';

@Component({
    selector: 'app-orders',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIcon,
        MatFabButton,
        MatMiniFabButton,
        RouterLink,
        InfiniteScrollModule,
    ],
    templateUrl: './orders.component.html',
    styleUrl: './orders.component.scss',
})
export class OrdersComponent {
    private ngUnsubscribe = new Subject<void>();
    public isLoading: boolean = true;
    public orders: Order[] = [];
    public pagination: PaginationMeta | null = null;
    constructor(
        private router: Router,
        private orderService: OrderService,
    ) {}

    loadOrders() {
        this.isLoading = true;
        this.orderService
            .getOrders({
                page: this.pagination?.page
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: ResponseWrapper<Order[]>) => {
                    const { data, meta } = response;
                    this.orders = [...this.orders, ...data];
                    this.pagination = meta.pagination!;
                    this.isLoading = false;
                },
                error: () => {
                    this.orders = [];
                    this.isLoading = false;
                },
            });
    }

    loadMore() {
        if (!this.pagination) {
            return;
        }
        this.pagination.page += 1;
        this.loadOrders();
    }

    ngOnInit() {
        this.loadOrders();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly OrderStatus = OrderStatus;
}
