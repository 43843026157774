import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { first } from 'rxjs';
import { AuthService } from '@/app/services';
import { ApiError } from '@/types';
import { User } from '@/app/models';
import { MatDialog } from '@angular/material/dialog';
import { TermsPopupComponent } from '@/app/components/terms/terms-popup.component';

@Component({
    selector: 'app-sign-up',
    standalone: true,
    imports: [ReactiveFormsModule, NgOptimizedImage, NgIf, NgClass, RouterLink],
    templateUrl: './sign-up.component.html',
    styleUrl: './sign-up.component.scss',
})
export class SignUpComponent {
    isLoading = false;
    error = '';
    isEnterpriseRoute: boolean = false;
    form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        fullName: ['', [Validators.required, Validators.minLength(2)]],
        termsAccepted: [false, Validators.requiredTrue],
    });
    isRegistered = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.isEnterpriseRoute = this.router.url === '/enterprise-sign-up';
        if (this.authService.token) {
            this.router.navigate(['/upload-idea']);
        }
    }

    toggleTerms() {
        if (this.form.controls.termsAccepted.getRawValue()) {
            this.dialog.open(TermsPopupComponent, {
                disableClose: true,
                width: '600px',
                autoFocus: false,
            });
        }
    }

    onSubmit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.isLoading = true;
        this.authService
            .register({
                email: this.form.controls.email.value!,
                password: this.form.controls.password.value!,
                fullName: this.form.controls.fullName.value!,
                isEnterprise: this.isEnterpriseRoute,
            })
            .pipe(first())
            .subscribe({
                next: (response: { jwt: string; user: User }) => {
                    this.isLoading = false;
                    if (response.jwt) {
                        this.router.navigate(['/upload-idea']);
                    } else {
                        this.isRegistered = true;
                    }
                },
                error: ({ error }: { error: ApiError }) => {
                    this.error = error?.error?.message || JSON.stringify(error);
                    this.isLoading = false;
                },
            });
    }
}
