import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TwoFactorAuthService {
    public is2FASavedSubject: BehaviorSubject<boolean>;
    public is2FASaved$: Observable<boolean>;

    constructor() {
        this.is2FASavedSubject = new BehaviorSubject<boolean>(false);
        this.is2FASaved$ = this.is2FASavedSubject.asObservable();
    }

    public get is2FASaved(): boolean {
        return this.is2FASavedSubject.value;
    }
}
