import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import { IpService } from '@/app/services';
import { Subject, takeUntil } from 'rxjs';
import { Ip } from '@/app/models';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { IdeaType } from '@/enums';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ResponseWrapper, PaginationMeta } from '@/types';
import { ShowroomNavComponent } from '@/app/components/showroom-nav/showroom-nav.component';

@Component({
    selector: 'app-public-ideas-list',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatIcon, RouterLink, InfiniteScrollModule, ShowroomNavComponent],
    templateUrl: './public-ideas-list.component.html',
})
export class PublicIdeasListComponent implements OnInit, OnDestroy {
    @Input() collectionId?: number;
    @Input() collectionSlug?: string;
    @Input() showEmptyInfo?: boolean;
    @Input() searchQuery?: string;

    public ips: Ip[] = [];
    private ngUnsubscribe = new Subject<void>();
    public isLoading: boolean = true;
    public pagination: PaginationMeta = {
        page: 1,
        pageCount: 1,
        pageSize: 50,
        total: 1,
    };

    isProfile: boolean = false;
    public searchControl: FormControl = new FormControl('');

    constructor(
        private ipService: IpService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.searchControl = this.ipService.getSearchControl();
    }

    loadIps() {
        this.isLoading = true;
        this.ipService
            .getPublicIps({
                page: this.pagination?.page,
                collectionId: this.collectionId,
                searchQuery: this.searchControl.value,
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: ResponseWrapper<Ip[]>) => {
                    const { data, meta } = response;
                    this.ips = data.filter(ip => ip.attributes.status === "PROTECTED");
                    // this.ips = data;
                    this.pagination = meta.pagination!;
                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                    this.ips = [];
                },
            });
    }

    ngOnInit() {
        this.isProfile = this.router.url !== '/protected/companies' && this.router.url !== '/protected';
        this.route.queryParamMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paramMap) => {
            this.pagination = {
                ...this.pagination,
                page: parseInt(paramMap.get('page') || '1'),
            };
            this.loadIps();
        });
    }

    loadMore() {
        if (!this.pagination) {
            return;
        }
        this.pagination.page += 1;
        this.loadIps();
    }

    onSearch() {
        this.pagination.page = 1;
        this.loadIps();
    }

    clearSearch() {
        this.searchControl.reset();
        this.pagination.page = 1;
        this.loadIps();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly IdeaType = IdeaType;
}
