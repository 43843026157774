<div *ngIf="products.length > 0" class="card-body justify-content-center p-4">
    <h1>{{ manifest.name }} Credits</h1>
    <ng-container *ngIf="user?.isEnterprise">
        <p class="text-center">
            <a class="note" style="text-decoration: none;" href="https://www.instantip.today/enterprise" target="_blank">
                Learn more about Enterprise Plan
                <img src="/assets/icons/redirect-icon.png" width="12" height="12" style="margin: 2px; margin-left: 5px;" priority />
            </a>
        </p>
    </ng-container>
    <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
    <div *ngIf="canUseWallet && !creditBuyOnly">
        <ul class="list-group mb-3">
            <li class="list-group-item d-flex p-0 align-items-center">
                <input
                    class="form-check-input d-flex m-0 ms-3"
                    type="checkbox"
                    value="wallet"
                    id="wallet"
                    (change)="toggleWallet()"
                    [checked]="useWallet"
                />
                <label class="form-check-label p-3 d-flex flex-grow-1 flex-column" for="wallet">
                    <p class="m-0">
                        <strong>Use credits from wallet</strong>
                    </p>
                    <small> Balance: {{ user?.wallet?.balance }} </small>
                </label>
            </li>
        </ul>
        <div class="text-center mb-2 opacity-50">Buy additional credits</div>
    </div>
    <ul class="list-group">
        <ng-container *ngFor="let product of products; let i = index">
            <ng-container *ngIf="!user?.isEnterprise || product.attributes.isEnterprise">
                
                <li class="list-group-item d-flex p-0 align-items-center">
                    <input
                        class="form-check-input d-flex m-0 ms-3"
                        type="checkbox"
                        value="wallet"
                        [id]="'product-' + i"
                        (change)="toggleProduct(product.id, $event)"
                        [checked]="selectedProductId === product.id"
                    />
                    <label class="form-check-label p-3 d-flex flex-grow-1 flex-column" [for]="'product-' + i">
                        <p class="m-0 d-flex justify-content-between">
                            <strong class="d-inline-flex"
                                ><span *ngIf="product.attributes.credits === 1 && (leftToPay || qty) > 1"
                                    >{{ leftToPay || qty }} &times;</span
                                >
                                {{ product.attributes.title }}</strong
                            >
                            <span
                                class="badge text-bg-dark ms-2 d-inline-flex align-self-center"
                                *ngIf="product.attributes.isEnterprise"
                                >Enterprise</span
                            >
                        </p>
                        <small>
                            {{ product.attributes.price | currency: product.attributes.currency }}
                            <span class="opacity-50" *ngIf="product.attributes.credits > 1"
                                >({{
                                    product.attributes.price / product.attributes.credits
                                        | currency: product.attributes.currency
                                }}
                                per credit)</span
                            >
                        </small>
                    </label>
                </li>

            </ng-container>
        </ng-container>
    </ul>
    <div *ngIf="totalPrice" class="text-center">
        <h2 class="my-4">Total to pay</h2>
        <ng-container *ngIf="!user?.isEnterprise">        
            <p class="mb-1" *ngIf="leftToPay">{{ leftToPay }} &times; IP protection</p>
            <p *ngIf="additionalCredits" class="text-success fw-bold mb-0">
                {{ additionalCredits }} &times; Additional
                {{
                    additionalCredits
                        | i18nPlural
                            : {
                                '=0': 'credits',
                                '=1': 'credit',
                                other: 'credits'
                            }
                }}
            </p>
        </ng-container>
        <h3 class="mt-3">{{ totalPrice | currency: 'USD' }}</h3>
    </div>
    <button
        class="btn btn-primary w-100 mt-4 mb-3"
        (click)="makeOrder()"
        [disabled]="
            isLoading ||
            (!totalPrice && !canUseWallet) ||
            (canUseWallet && !useWallet && !totalPrice) ||
            (useWallet && leftToPay && !totalPrice)
        "
    >
        <div *ngIf="isLoading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <span *ngIf="!isLoading">Submit</span>
    </button>
    <div *ngIf="isLoading && ideas && ideas.length > 0 && products.length > 0" style="padding: 10px;">
        <small>
            We are now Protecting your Idea. This might take a minute. Congratulations on creating more value in the world.
        </small>
    </div>
</div>
