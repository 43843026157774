import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { JsonPipe, NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { AuthService } from '@/app/services';
import { first } from 'rxjs';
import { ApiError } from '@/types';
import { CustomValidators } from '@/app/utils';

@Component({
    selector: 'app-reset-password',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgOptimizedImage, RouterLink, NgIf, JsonPipe, NgClass],
    templateUrl: './reset-password.component.html',
    styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
    isLoading = false;
    submitted = false;
    error: string = '';
    form = this.formBuilder.group(
        {
            password: ['', [Validators.required, Validators.minLength(8)]],
            repeatPassword: ['', [Validators.required]],
        },
        {
            validators: [CustomValidators.match('password', 'repeatPassword')],
        },
    );

    code: string | null = null;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        if (this.authService.token) {
            this.router.navigate(['/upload-idea']);
        }
        this.code = this.route.snapshot.queryParamMap.get('code');
        if (!this.code) {
            this.router.navigate(['/sign-in']);
        }
    }

    onSubmit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.isLoading = true;
        this.authService
            .resetPassword({
                password: this.form.controls.password.value!,
                passwordConfirmation: this.form.controls.repeatPassword.value!,
                code: this.code!,
            })
            .pipe(first())
            .subscribe({
                next: () => {
                    this.submitted = true;
                    this.isLoading = false;
                    this.form.reset();
                    setTimeout(() => {
                        this.router.navigate(['/sign-in']);
                    }, 3000);
                },
                error: ({ error }: { error: ApiError }) => {
                    this.error = error?.error?.message || JSON.stringify(error);
                    this.isLoading = false;
                },
            });
    }
}
