import {Component, ViewChild} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { AuthService, UserService } from '@/app/services';
import { User } from '@/app/models';
import { SideNavComponent, ToolbarComponent } from '@/app/components/shared';
import { MatDrawer, MatDrawerContainer, MatDrawerContent } from '@angular/material/sidenav';
import { MatFormField } from '@angular/material/form-field';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OnboardingComponent } from '@/app/components/onboarding/onboarding.component';
import { environment } from '@/environments/environment';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        NgOptimizedImage,
        RouterLink,
        NgIf,
        ToolbarComponent,
        MatDrawerContainer,
        MatFormField,
        MatDrawer,
        MatDrawerContent,
        MatButton,
        SideNavComponent,
        CommonModule
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    private ngUnsubscribe = new Subject<void>();
    public isLoading: boolean = false;
    public user: User | null = null;
    public dialogRef: MatDialogRef<OnboardingComponent> | null = null;
    public isPublicWebsite: boolean = false;
    public appName = environment.appName;
    @ViewChild('drawer') drawer: MatDrawer | undefined;

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {

        // * * * * *

        /* Affects only to dev env. In production the domain 
           for /protected will be different, 
           therefore user is not logged in. */

        this.isPublicWebsite = 
            ( 
                window.location.href.startsWith('http://localhost:8081/protected') ||
                window.location.href.startsWith('https://dev-app.instantip.app/protected') ||
                window.location.href.startsWith('https://instantip.today/protected') ||
                window.location.href.startsWith('https://instantip.app/protected')
            );

        // * * * * *

        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
            if (
                this.user &&
                this.user.isEnterprise &&
                !this.user.hideOnboarding &&
                !this.user?.tokenTracker &&
                !this.dialogRef
            ) {
                this.dialogRef = this.dialog.open(OnboardingComponent, {
                    width: '500px',
                    disableClose: true,
                });
                this.dialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(() => {
                        this.userService.getUser();
                    });
            }
        });
        this.authService.token$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((token) => {
            if (!token) {
                return;
            }
            this.isLoading = true;
            this.userService
                .getUser()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    this.isLoading = false;
                });
        });
    }

    toggleDrawer() {
        this.drawer?.toggle();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
