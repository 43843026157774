<ng-container *ngIf="!isTokenInvalid">
    <div class="card-body justify-content-center p-4">
        <h1>Sign In</h1>
        <div id="form-wrapper">
            <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
            <form *ngIf="!show2FA" id="sign-in-form" [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="mb-3">
                    <label class="form-label" for="email">Email address</label>
                    <input
                        class="form-control form-control-lg"
                        id="email"
                        type="text"
                        formControlName="email"
                        [ngClass]="{
                            'is-invalid': form.controls.email.touched && form.controls.email.errors
                        }"
                        required
                    />
                    <div class="invalid-feedback" *ngIf="form.controls.email.hasError('required')">Required field</div>
                    <div class="invalid-feedback" *ngIf="form.controls.email.hasError('email')">
                        Incorrect email address
                    </div>
                </div>
                <div class="mb-3">
                    <div class="d-flex justify-content-between">
                        <label class="form-label" for="password">Password</label>
                        <a [routerLink]="['/remind-password']"><small>Don't remember?</small></a>
                    </div>
                    <div class="input-container">
                        <input
                            class="form-control form-control-lg"
                            [type]="showPassword ? 'text' : 'password'"
                            id="password"
                            formControlName="password"
                            [ngClass]="{
                                'is-invalid': form.controls.password.touched && form.controls.password.errors
                            }"
                            required
                        />
                        <div class="invalid-feedback" *ngIf="form.controls.password.hasError('required')">
                            Required field
                        </div>
                        <span class="eye-icon" (click)="onEyeClicked()"></span>
                    </div>
                </div>
                <button class="btn btn-primary w-100 mt-5 mb-3" type="submit" [disabled]="!form.valid">
                    <span *ngIf="isLoading" class="spinner-border">
                        <span class="visually-hidden">Loading...</span>
                    </span>
                    <span *ngIf="!isLoading">Submit</span>
                </button>
            </form>
            <app-verify-code
                *ngIf="show2FA"
                [identifier]="form.controls.email.value!"
                [returnUrl]="returnUrl"
                (onCancel)="cancel2FA()"
            />
        </div>
    </div>
    <div class="card-footer pb-5 pt-2 text-center" *ngIf="!show2FA">
        <p>Don’t have an account yet?</p>
        <a [routerLink]="['/upload-idea']">Protect Your Idea</a>
    </div>
</ng-container>
<app-invalid-token *ngIf="isTokenInvalid" />
