<div class="card-body justify-content-center p-4">
    <div class="wallet p-4">
        <h1>{{ user?.wallet?.balance }} {{ manifest.name }} Credits</h1>
        <small class="opacity-50">You can spend available credits to protect your Ideas</small>
        <a class="btn btn-outline-secondary mt-4" [routerLink]="['/checkout']" [queryParams]="{ creditsOnly: true }"
            >Buy More Credits</a
        >
    </div>
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a
                class="nav-link"
                [routerLink]="['credits']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Credits</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                [routerLink]="['orders']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Orders</a
            >
        </li>
    </ul>
    <div class="tab-content" style="border-top: 1px #dddddd solid;">
        <router-outlet></router-outlet>
    </div>
</div>
