<div class="card-body justify-content-center p-4">
    <h1>{{ appName }} Terms and Conditions</h1>
    <p>
        These {{ appName }} Terms of Service (the “Terms”) are a legal agreement between
        {{ appName }} (“we,” “our,” “us”) and you, the visitor, governing your access to and use of all content
        and functionalities available at the instantip.today and instantip.app websites and any other sites or webpages
        to which these Terms are applied and subdomains of the foregoing (each, a “Site”), and any and all products and
        services provided via any Site (collectively, the “Services”).
    </p>
    <p>
        THIS IS A LEGALLY BINDING CONTRACT. BY ACCESSING OR USING THE SERVICES OR BY INDICATING YOUR ACCEPTANCE OF THESE
        TERMS, YOU ACKNOWLEDGE AND REPRESENT THAT YOU HAVE READ THESE TERMS, UNDERSTAND THEM, AND AGREE TO BE BOUND BY
        THEM. YOU ALSO REPRESENT AND WARRANT THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS CONTRACT AND ARE NOT
        PROHIBITED BY LAW FROM ACCESSING OR USING THE SERVICES. YOU DO NOT HAVE TO ENTER INTO THESE TERMS, BUT IF YOU DO
        NOT, YOU DO NOT HAVE PERMISSION TO, AND YOU MAY NOT, USE THE SERVICES.
    </p>
    <p>
        {{ appName }} and Blockchain Life, LLC assumes no responsibilities of verifying ownership for any
        intellectual property. By using {{ appName }} you acknowledge your legal ownership, property or other
        rights, and your ability and authority to grant rights related to assets, some of which will be recorded on the
        blockchain. You agree to only make truthful confirmations and declarations and acknowledge that
        {{ appName }} in no way has any obligations to determine veracity; provided, that {{ appName }} may
        cancel any certifications or Services, without refund, if it determines in its sole discretion that any such
        required declarations are untrue, suspect or have been removed. You authorize {{ appName }} to process
        permanent publication of your declarations, provided data and User Content permanently onto IPFS, Polygon
        Network (and other decentralized servers), blockchains, and registries as part of the Services.
    </p>
    <p>
        {{ appName }} reserves the right to refuse protecting Intellectual Property that is illegal, abusive,
        harassing, harmful to reputation, pornographic, indecent, profane, obscene, hateful, racist, or otherwise
        objectionable.
    </p>
    <p>
        {{ appName }} makes no legal claims. Seek legal counsel if you have any questions or concerns. You should
        not construe any information from or about {{ appName }} as legal or business advice. The information
        provided is to be used for educational purposes only.
    </p>
    <p>
        You must do your own analysis before making any decisions on your own personal circumstances. If you choose to
        purchase any {{ appName }} product or service you indemnify and hold harmless Blockchain Life, LLC and its
        officers, affiliates, directors, partners, independent contractors, and against from and against any and all
        demands, claims, damages to persons or property, losses, and liabilities, including reasonable attorneys’ fees
        (collectively “Claims”) arising out of or caused by the negligence of Blockchain Life, LLC.
    </p>
    <p>
        Users uploading their respective Intellectual Property retain ownership of their smart contract. Please note,
        that this digital asset remains the Instant IP™ digital wallet. This process is much like authors who send
        their books to the Library of Congress to be “housed” in Washington DC. The author always retains ownership of
        their book; however, the asset is housed in the Library of Congress. If users desire, they can request the
        transfer of their digital asset to their own digital wallet with a simple transfer feature found inside the app.
    </p>
    <p>
        When the smart contract is executed, the software protocol creates an immutable (unchangeable) record of the
        transaction, linking it to the block of prior transactions in that blockchain. The cryptographic links between
        each block make it virtually impossible to go back and edit or delete prior transaction data.
    </p>
    <p>Most document types can be protected. These include:</p>
    <ul>
        <li>Images – PNG, JPG, SVG, EPS, PSD, GIFs.</li>
        <li>Video – MP4, WEBM, MOV, AVIs.</li>
        <li>Audio – MP3, M4A, WAVs.</li>
        <li>Text – DOC, DOCX, XLS, XLSX, PDFs.</li>
        <li>3D/AR - glb, gltf, zip that contains a .</li>
    </ul>
</div>
