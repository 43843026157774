import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IdeaService, IpService, ManifestService, OrderService, ProductService, StorageService, UserService } from '@/app/services';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { Idea, Product, User } from '@/app/models';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-checkout',
    standalone: true,
    imports: [ReactiveFormsModule, CommonModule],
    templateUrl: './checkout.component.html',
    styleUrl: './checkout.component.scss',
})
export class CheckoutComponent {
    private ngUnsubscribe = new Subject<void>();
    public isLoading: boolean = true;
    public ideas: Idea[] = [];
    public products: Product[] = [];
    public selectedProductId: number | null = null;
    public totalPrice: number = 0;
    public leftToPay: number = 0;
    public qty: number = 0;
    public additionalCredits: number = 0;
    public user: User | null = null;
    public useWallet: boolean = false;
    public error: string | null = null;
    public creditBuyOnly: boolean = false;

    manifest: any = {};

    constructor(
        private router: Router,
        private productService: ProductService,
        private ideaService: IdeaService,
        private orderService: OrderService,
        private userService: UserService,
        private ipService: IpService,
        private route: ActivatedRoute,
        private manifestService: ManifestService,
    ) {

        this.manifestService.getManifest().subscribe((manifest) => {
            this.manifest = manifest;
        });
    }

    calculateTotalPrice() {
        const selectedProduct = this.products?.find((product) => product.id === this.selectedProductId);
        this.leftToPay = Math.max(
            this.useWallet ? this.ideas.length - (this.user?.wallet?.balance || 0) : this.ideas.length,
            0,
        );

        if (!selectedProduct) {
            this.totalPrice = 0;
            return;
        }
        let credits = 0;
        this.qty = Math.ceil((this.leftToPay || 1) / selectedProduct.attributes.credits);
        credits = this.qty * selectedProduct.attributes.credits;
        this.totalPrice = this.qty * selectedProduct.attributes.price;
        this.additionalCredits = credits - (this.useWallet ? this.leftToPay : this.ideas.length);
    }
    loadProducts() {
        this.isLoading = true;
        this.productService
            .getProducts()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: Product[]) => {

                    if (this.user?.isEnterprise)
                        // The first product is for individual owners
                        this.products = [...this.products, ...response.slice(1)];
                    else
                        this.products = [...this.products, ...response];

                    if (!this.canUseWallet) {       
                        this.selectedProductId = response[0].id;
                    }
                    this.calculateTotalPrice();
                    this.isLoading = false;
                },
                error: () => {
                    this.products = [];
                    this.isLoading = false;
                },
            });
    }

    toggleProduct(id: number | null, event: Event) {
        const target = event.target as HTMLInputElement;
        this.selectedProductId = target.checked ? id : null;
        this.calculateTotalPrice();
    }

    toggleWallet() {
        this.useWallet = !this.useWallet;
        this.calculateTotalPrice();
    }

    get canUseWallet() {
        return this.user && this.user.wallet && this.user.wallet.balance && this.user.wallet.balance;
    }

    ngOnInit() {

        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
            this.loadProducts();
        });

        this.ideas = this.ideaService.ideas;

        if (this.route.snapshot.queryParams['creditsOnly']) {
            this.creditBuyOnly = true;
            this.ideas = [];
        } else {
            if (!this.ideas.length) {
                this.router.navigate(['/upload-idea']);
            }
        }
    }

    makeOrder() {
        this.isLoading = true;

        const ips = this.ideas.map((idea) => ({
            title: idea.title,
            description: idea.description,
            isPublic: idea.isPublic,
            mintOnBlockchain: idea.mintOnBlockchain,
            sendToPrivateVault: idea.sendToPrivateVault,
            type: idea.ideaType,
            content: idea.content,
            uid: idea.uid,
            extension: idea.extension,
        }));

        if (this.useWallet && !this.selectedProductId) {
            this.ipService
                .addIps({
                    ips,
                })
                .subscribe({
                    next: async () => {
                        this.ideaService.clearIdeas();
                        await lastValueFrom(this.userService.getUser())
                            .then(() => {
                                this.isLoading = false;
                                this.router.navigate(['/ideas']);
                            })
                            .catch(() => {
                                this.isLoading = false;
                            });
                    },
                    error: ({ error }) => {
                        this.error = error?.error?.message || 'An error occurred, please try again in a moment';
                        this.isLoading = false;
                    },
                });
        } else {
            this.orderService
                .makeOrder({
                    productId: this.selectedProductId,
                    useWallet: this.useWallet ? 1 : 0,
                    ips,
                })
                .subscribe({
                    next: (response) => {
                        this.isLoading = false;
                        if (response.url) {
                            this.ideaService.clearIdeas();
                            window.location.href = response.url;
                        }
                    },
                    error: ({ error }) => {
                        this.error = error?.error?.message || 'An error occurred, please try again in a moment';
                        this.isLoading = false;
                    },
                });
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
