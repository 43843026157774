import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { environment } from '@/environments/environment';
import { TermsComponent } from '@/app/components/terms/terms.component';
import {MatDialogActions, MatDialogContent, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-terms-popup',
    standalone: true,
    imports: [RouterLink, NgIf, TermsComponent, MatDialogContent, MatDialogActions],
    templateUrl: './terms-popup.component.html',
})
export class TermsPopupComponent {
    appName: string = environment.appName;
    constructor(public dialogRef: MatDialogRef<TermsPopupComponent>) {}

    public close() {
        this.dialogRef.close();
    }
}
