<h2 mat-dialog-title class="p-4">{{ title }}</h2>

<div class="px-5">
    <div class="text-yellow-600 mb-4">
        Note: Token Tracker Name can never be changed due to blockchain technology. Your Profile Picture can be changed later.
    </div>
    
    <div class="alert alert-danger mb-4" role="alert" *ngIf="error">
        {{ error }}
    </div>
    
    <form [formGroup]="form" class="my-4">
        <!-- Token Tracker Field -->
        <div class="mb-3">
            <label for="tokenTracker" class="form-label d-flex align-items-center">
                Token Tracker Name (Required)
                <span class="text-danger ms-1">*</span>
                <span *ngIf="form.get('tokenTracker')?.valid && form.get('tokenTracker')?.value" 
                      class="ms-2 text-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" 
                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" 
                         stroke-linejoin="round">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                    </svg>
                </span>
            </label>
            <input
                class="form-control form-control-lg"
                [ngClass]="{
                    'border-danger': form.get('tokenTracker')?.invalid && form.get('tokenTracker')?.touched,
                    'border-success': form.get('tokenTracker')?.valid && form.get('tokenTracker')?.value
                }"
                type="text"
                required
                placeholder="i.e. John Smith IP"
                [formControl]="form.controls.tokenTracker"
                id="tokenTracker"
            />
            <small *ngIf="user?.tokenTracker" class="opacity-50">Token Tracker Name cannot be changed</small>
        </div>

        <!-- Profile Picture Field -->
        <img
            class="m-2 d-block mx-auto"
            *ngIf="user && user.logo"
            [ngSrc]="user.logo.formats.thumbnail.url"
            [width]="user.logo.formats.thumbnail.width"
            [height]="user.logo.formats.thumbnail.height"
        />
        <div>
            <label for="logoUpload" class="form-label d-flex align-items-center">
                Your Profile Picture (Required)
                <span class="text-danger ms-1">*</span>
                <span *ngIf="form.get('logo')?.valid" class="ms-2 text-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" 
                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" 
                         stroke-linejoin="round">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                    </svg>
                </span>
            </label>
            <input
                style="max-width: 100%; white-space: break-spaces;"
                type="file"
                required
                id="logoUpload"
                (change)="onImagePicked($event)"
                accept="image/png,image/svg+xml,image/jpg,image/gif"
                [ngClass]="{
                    'border-danger': form.get('logo')?.invalid && form.get('logo')?.touched,
                    'border-success': form.get('logo')?.valid
                }"
            />
        </div>
    </form>
    
    <app-two-factor-auth-settings />
</div>

<div mat-dialog-actions class="p-4 px-md-5 pt-0 pb-5" align="end">
    <button
        class="btn btn-primary"
        [disabled]="!form.valid || isLoading.submit || isLoading.cancel"
        (click)="onSubmit()"
    >
        <span *ngIf="isLoading.submit" class="spinner-border spinner-border-sm d-flex"></span>
        <span *ngIf="!isLoading.submit">Submit</span>
    </button>
</div>