<ul
    class="list"
    *ngIf="ips.length"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [infiniteScrollContainer]="'.mat-drawer-content'"
    [scrollWindow]="false"
    [fromRoot]="true"
    (scrolled)="loadMore()"
    [alwaysCallback]="true"
    [infiniteScrollDisabled]="!pagination || pagination.page === pagination.pageCount"
>
    <ng-container *ngIf="isPublicRoute">
    <li class="idea">
        <a class="external-link" (click)="openPublicWebsite()">
            See your Protected IP in Public Vault
            <img src="/assets/icons/redirect-icon.png" width="12" height="12" style="margin: 2px; margin-left: 5px;" priority />
        </a>
    </li>
    </ng-container>
    
    <ng-container *ngFor="let ip of ips">
    <ng-container *ngIf="!isPublicRoute || (ip.attributes.isPublic && ip.attributes.status === 'PROTECTED')">
    <li class="idea">
        <a [routerLink]="!isPublicRoute ? ['/ideas', ip.id] : null" 
            [class.disabled-link]="isPublicRoute"
            (click)="isPublicRoute ? $event.preventDefault() : null">
            <span class="thumbnail me-3">
                <mat-icon
                    *ngIf="!ip.attributes.thumbnail || ip.attributes.status !== 'PROTECTED' || ip.attributes.type === IpType.Text"
                    fontIcon="article"
                    class="overflow-visible"
                />
                <img
                    *ngIf="ip.attributes.thumbnail && ip.attributes.status === 'PROTECTED' && ip.attributes.type !== IpType.Text"
                    [src]="ip.attributes.thumbnail"
                    onerror="this.style.display='none'"
                />
            </span>
            <span class="d-block">
                <ng-container *ngIf="!isPublicRoute">                
                    <span
                        class="badge rounded-pill mb-1"
                        [ngClass]="{
                            'text-bg-primary': ip.attributes.status === 'PROTECTED',
                            'text-bg-info': ip.attributes.status === 'PROTECTION_PENDING',
                            'text-bg-success': ip.attributes.status === 'PAID',
                            'text-bg-warning': ip.attributes.status === 'PAYMENT_PENDING',
                            'text-bg-danger': ip.attributes.status === 'PAYMENT_FAILED'
                        }"
                        >{{ ip.attributes.status.replace('_', ' ') }}</span
                    >
                    <span *ngIf="ip.attributes.isPublic" class="badge rounded-pill mb-1 text-bg-success">Public</span>
                    <span *ngIf="ip.attributes.sendToPrivateVault" class="badge rounded-pill mb-1 text-bg-info">In Private Wallet</span>
                </ng-container>
                <span class="d-block">
                    <strong class="d-block" style="word-break: break-all">{{ ip.attributes.title }}</strong>
                    <small class="opacity-50">{{ ip.attributes.createdAt | date: 'medium' }}</small>
                </span>
            </span>
        </a>
    </li>
    </ng-container>
    </ng-container>
</ul>
<ul class="list" *ngIf="isLoading || (!ips.length && !isLoading && showEmptyInfo && !orderId) || !ips.length && !isLoading && showEmptyInfo && orderId">
    <li class="idea">
        <div class="text-center pb-5 pt-3" *ngIf="!ips.length && !isLoading && showEmptyInfo && !orderId">
            <strong>You don't have any Ideas yet</strong>
            <a class="btn btn-outline-secondary mt-4" [routerLink]="['/upload-idea']">Protect Your Ideas Now</a>
        </div>
        <div class="text-center pb-5 pt-3" *ngIf="!ips.length && !isLoading && showEmptyInfo && orderId">
            <small class="opacity-50">Ideas from this order have been removed</small>
        </div>
        <div *ngIf="isLoading" class="p-5 my-5 d-flex justify-content-center">
            <div>
                <div class="text-center" style="padding: 40px;">
                    <small class="opacity-50">Verifying your protected IP - just a moment.</small>
                </div>
                <div class="text-center" style="padding: 40px;">
                    <div class="spinner-border">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </li>
</ul>