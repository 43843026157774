<div class="card-body justify-content-center p-4">
    <ul
        class="list"
        *ngIf="orders.length"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="'.mat-drawer-content'"
        [scrollWindow]="false"
        [fromRoot]="true"
        (scrolled)="loadMore()"
        [alwaysCallback]="true"
        [infiniteScrollDisabled]="!pagination || pagination.page === pagination.pageCount"
    >
        <li *ngFor="let order of orders" class="d-flex align-items-center order">
            <a [routerLink]="['/orders', order.id]">
                <span class="d-block w-100">
                    <span
                        class="badge rounded-pill mb-1"
                        [ngClass]="{
                            'text-bg-primary': order.attributes.status === OrderStatus.Completed.toString(),
                            'text-bg-success': order.attributes.status === OrderStatus.Paid.toString(),
                            'text-bg-warning': order.attributes.status === OrderStatus.PaymentPending.toString(),
                            'text-bg-danger': order.attributes.status === OrderStatus.PaymentFailed.toString()
                        }"
                        >{{ order.attributes.status.replace('_', ' ') }}</span
                    >
                    <strong class="d-flex justify-content-between">
                        <span class="d-flex"
                            >{{ order.attributes.item?.qty }} &times; {{ order.attributes.item?.title }}</span
                        >
                        <span class="d-flex">{{
                            order.attributes.price | currency: order.attributes.item?.currency
                        }}</span>
                    </strong>
                    <small class="opacity-50">{{ order.attributes.createdAt | date: 'medium' }}</small>
                </span>
            </a>
        </li>
    </ul>
    <div class="text-center pb-5 pt-3" *ngIf="!orders.length && !isLoading">
        <strong>You don't have any orders yet</strong>
    </div>
    <div *ngIf="isLoading" class="p-5 my-5 d-flex justify-content-center">
        <div class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>
