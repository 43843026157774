import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, RouterLink} from '@angular/router';
import { IpService } from '@/app/services';
import { Subject, takeUntil } from 'rxjs';
import {Collection, Ip} from '@/app/models';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { IdeaType } from '@/enums';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ResponseWrapper, PaginationMeta } from '@/types';
import { ShowroomNavComponent } from '@/app/components/showroom-nav/showroom-nav.component';

@Component({
    selector: 'app-public-idea-collections-list',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatIcon, RouterLink, InfiniteScrollModule, ShowroomNavComponent],
    templateUrl: './public-idea-collections-list.component.html',
})
export class PublicIdeaCollectionsListComponent implements OnDestroy {
    @Input() showEmptyInfo?: boolean;
    public collections: Collection[] = [];
    private ngUnsubscribe = new Subject<void>();
    public isLoading: boolean = true;
    public pagination: PaginationMeta = {
        page: 1,
        pageCount: 1,
        pageSize: 50,
        total: 1,
    };

    public searchControl: FormControl = new FormControl('');

    constructor(
        private ipService: IpService,
        private route: ActivatedRoute,
    ) {
        this.searchControl = this.ipService.getSearchControl();
        this.route.queryParamMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paramMap) => {
            this.pagination = {
                ...this.pagination,
                page: parseInt(paramMap.get('page') || '1')
            };
            this.loadCollections();
        });
    }

    loadCollections() {
        this.isLoading = true;
        this.ipService
            .getPublicIpCollections({
                page: this.pagination?.page,
                searchQuery: this.searchControl != null && this.searchControl.value != null && this.searchControl.value.trim() != "" ? this.searchControl.value : "",
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: ResponseWrapper<Collection[]>) => {
                    const { data, meta } = response;
                    this.collections = data;
                    this.pagination = meta.pagination!;
                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                    this.collections = [];
                },
            });
    }

    loadMore() {
        if (!this.pagination) {
            return;
        }
        this.pagination.page += 1;
        this.loadCollections();
    }

    onSearch() {
        this.pagination.page = 1;
        this.loadCollections();
    }

    clearSearch() {
        this.searchControl.reset();
        this.pagination.page = 1;
        this.loadCollections();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
