import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@/environments/environment';
import { Collection, Ip } from '@/app/models';
import { Endpoint } from '@/enums';
import { ResponseWrapper, StrapiQs } from '@/types';
import { map } from 'rxjs/operators';
import qs from 'qs';
import { FormControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class IpService {
    constructor(private http: HttpClient) {}

    searchControl = new FormControl('');
    getSearchControl(): FormControl {
        return this.searchControl;
    }
        
    getIps(params?: { page?: number; orderId?: number }): Observable<ResponseWrapper<Ip[]>> {
        const searchParams: StrapiQs = {
            sort: ['createdAt:desc'],
            pagination: {
                page: params?.page || 1,
            },
        };

        if (params?.orderId) {
            searchParams.filters = {
                order: {
                    id: {
                        $eq: params.orderId,
                    },
                },
            };
        }

        return this.http.get<ResponseWrapper<Ip[]>>(
            `${environment.apiUrl + Endpoint.IPS}?${qs.stringify(searchParams)}`,
            {
                withCredentials: true,
            },
        );
    }

    addIps(data: any): Observable<any> {
        return this.http.post<any>(environment.apiUrl + Endpoint.IPS, data, {
            withCredentials: true,
        });
    }

    updateIp({
        id,
        title,
        description,
        isPublic,
    }: {
        id: number;
        title: string;
        description: string;
        isPublic: boolean;
    }): Observable<any> {
        return this.http.put<any>(
            `${environment.apiUrl + Endpoint.IPS}/${id}`,
            {
                data: {
                    title,
                    description,
                    isPublic,
                },
            },
            {
                withCredentials: true,
            },
        );
    }

    getIp(id: number): Observable<Ip> {
        return this.http
            .get<ResponseWrapper<Ip>>(`${environment.apiUrl + Endpoint.IPS}/${id}`, {
                withCredentials: true,
            })
            .pipe(map((response) => response.data));
    }

    getPresignedUrl(id: number): Observable<string> {
        return this.http
            .get<{
                url: string;
            }>(`${environment.apiUrl + Endpoint.IPS}/${id}/presigned-url`, {
                withCredentials: true,
            })
            .pipe(map((response) => response.url));
    }

    transferToWallet(id: number, recipient: string, saveRecipient: boolean): Observable<any> {

        return this.http
            .get<{
                url: string;
            }>(`${environment.apiUrl + Endpoint.IPS}/${id}/transfer?recipient=`+ recipient +'&saveRecipient='+ saveRecipient, {
                withCredentials: true,
            })
            .pipe(map((response) => response));
    }

    deleteIp(id: number): Observable<void> {
        return this.http.delete<void>(`${environment.apiUrl + Endpoint.IPS}/${id}`, {
            withCredentials: true,
        });
    }

    getPublicIps(params?: { page?: number; collectionId?: number; searchQuery?: string }): Observable<ResponseWrapper<Ip[]>> {
        const searchParams: StrapiQs = {
            sort: ['createdAt:desc'],
            pagination: {
                page: params?.page || 1,
                pageSize: 50,
            },
        };

        if (params && params.collectionId) {
            searchParams.filters = <any>{
                user: params.collectionId,
            };
        }

        if (params && params?.searchQuery) {
            searchParams.filters = {
                ...searchParams.filters,
                title: {
                    $containsi: params.searchQuery,
                },
            };
        }

        return this.http.get<ResponseWrapper<Ip[]>>(
            `${environment.apiUrl + Endpoint.PUBLIC_IPS}?${qs.stringify(searchParams)}`,
        );
    }

    getPublicIp(id: number): Observable<Ip> {
        return this.http
            .get<ResponseWrapper<Ip>>(`${environment.apiUrl + Endpoint.PUBLIC_IPS}/${id}`)
            .pipe(map((response) => response.data));
    }

    getPublicIpCollections(params?: { page?: number; searchQuery?: string }): Observable<ResponseWrapper<Collection[]>> {
        const searchParams: StrapiQs = {
            sort: ['createdAt:desc'],
            pagination: {
                page: params?.page || 1,
                pageSize: 50,
            },
        };

        return this.http.get<ResponseWrapper<Collection[]>>(
            `${environment.apiUrl + Endpoint.PUBLIC_IP_COLLECTIONS}?${qs.stringify(searchParams)}&query=${params?.searchQuery}`,
        );
    }

    getPublicIpCollection(id: number | string): Observable<Collection> {
        return this.http
            .get<ResponseWrapper<Collection>>(`${environment.apiUrl + Endpoint.PUBLIC_IP_COLLECTIONS}/${id}`)
            .pipe(map((response) => response.data));
    }

    transferNFT(id: number, recipient: string, saveRecipient: boolean): Observable<any> {

        const url = environment.apiUrl + Endpoint.IPS +"/transfer/"+ id +"?recipient="+ recipient +"&saveRecipient="+ saveRecipient;
        return this.http.get<any>(url, { withCredentials: true });

        /*
        return this.http.get<any>(
            `${environment.apiUrl + Endpoint.IPS}/${id}/transfer-nft?recipient=${recipient}&saveRecipient=${saveRecipient}`,
            {
                withCredentials: true,
            },
        );
        */
    }
}
