import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-edit-profile-cover-pictures',
    standalone: true,
    imports: [],
    templateUrl: './edit-profile-cover-pictures.component.html',
    styleUrl: './edit-profile-cover-pictures.component.scss',
})
export class EditProfileCoverPicturesComponent {
    profile: File | undefined;
    cover: File | undefined;

    constructor(public dialogRef: MatDialogRef<EditProfileCoverPicturesComponent>) {}

    changeProfile(event: Event) {
        this.profile = this.onImagePicked(event);
    }

    changeCover(event: Event) {
        this.cover = this.onImagePicked(event);
    }

    onImagePicked(event: Event): File | undefined {
        const e = event.target as HTMLInputElement;
        if (!e || !e.files?.length) {
            return undefined;
        }
        return e.files[0];
    }

    back() {
        this.dialogRef.close({
            profile: this.profile,
            cover: this.cover,
        });
    }
}
