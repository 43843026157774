import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { OrderService } from '@/app/services';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-pay-now',
    standalone: true,
    imports: [ReactiveFormsModule, NgOptimizedImage, MatIcon, NgIf],
    templateUrl: './pay-now.component.html',
    styleUrl: './pay-now.component.scss',
})
export class PayNowComponent {
    @Input() orderId: number | null = null;
    isLoading = false;

    constructor(private orderService: OrderService) {}

    async getStripeSession() {
        this.isLoading = true;
        this.orderService.getStripeSession(this.orderId!, true).subscribe({
            next: (orderStripeSession) => {
                if (orderStripeSession) {

                    if (orderStripeSession.url)
                        window.location.href = orderStripeSession.url;
                }
            },
            error: (error) => {
                this.isLoading = false;  // Make sure to reset loading state on error
            },
            complete: () => {
                this.isLoading = false;
            }
        });
    }

    /*
    async getStripeSession() {
        alert("Order ID: "+ this.orderId);
        this.isLoading = true;
        const orderStripeSession = await this.orderService.getStripeSession(this.orderId!);
        alert("orderStripeSession: "+ JSON.stringify(orderStripeSession));
        const stripeSessions = await lastValueFrom(orderStripeSession);
        this.isLoading = false;
        if (stripeSessions) {
            alert("Stripe URL: "+ JSON.stringify(stripeSessions));
            window.location.href = stripeSessions.url;
        }
    } */
}
