import { Component, EventEmitter, Output } from '@angular/core';

import { UserService } from '@/app/services';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { User } from '@/app/models';
import { Subject, takeUntil } from 'rxjs';
import { MatDivider } from '@angular/material/divider';

@Component({
    selector: 'app-toolbar',
    standalone: true,
    imports: [NgOptimizedImage, RouterLink, MatMenuTrigger, MatMenu, MatMenuItem, MatButton, NgIf, MatDivider],
    templateUrl: './toolbar.component.html',
    styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent {
    @Output() onNavToggle = new EventEmitter();
    private ngUnsubscribe = new Subject<void>();
    public user: User | null = null;

    constructor(private userService: UserService) {}

    ngOnInit() {
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
        });
    }

    toggleNav() {
        this.onNavToggle.emit();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
