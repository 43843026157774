import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {NgClass, NgIf, NgOptimizedImage} from '@angular/common';
import { AuthService } from '@/app/services';
import { first } from 'rxjs';
import { ApiError } from '@/types';

@Component({
    selector: 'app-remind-password',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgOptimizedImage, RouterLink, NgIf, NgClass],
    templateUrl: './remind-password.component.html',
    styleUrl: './remind-password.component.scss',
})
export class RemindPasswordComponent {
    isLoading = false;
    submitted = false;
    error = '';
    form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
    });

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        const code = this.route.snapshot.queryParamMap.get('code');
        if (code) {
        }
        if (this.authService.token) {
            this.router.navigate(['/upload-idea']);
        }
    }

    onSubmit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.isLoading = true;
        this.authService
            .remindPassword(this.form.controls.email.value!)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.submitted = true;
                    this.isLoading = false;
                    this.form.reset();
                },
                error: ({ error }: { error: ApiError }) => {
                    this.error = error?.error?.message || JSON.stringify(error);
                    this.isLoading = false;
                },
            });
    }
}
