<div class="h-100 d-flex flex-column">
    <div class="d-flex justify-content-center py-4">
        <img ngSrc="/assets/logo_black.png" width="111" height="91" priority />
    </div>
    <ul class="nav flex-column flex-grow-1">
        <li class="nav-item">
            <a class="nav-link" [routerLink]="['/upload-idea']" (click)="toggleNav()"
                ><mat-icon fontIcon="add" /> Protect Ideas</a
            >
        </li>
        <li class="nav-item">
            <a class="nav-link" [routerLink]="['/ideas']" (click)="toggleNav()"
                ><mat-icon fontIcon="lightbulb" /> Protected IP</a
            >
        </li>
        <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/orders']" (click)="toggleNav()"
                ><mat-icon fontIcon="receipt_long" /> Orders</a
            >
        </li>
        <li class="nav-item">
            <a class="nav-link" [routerLink]="['/wallet']" (click)="toggleNav()"
                ><mat-icon fontIcon="account_balance_wallet" /> Credits
                <span class="badge rounded-pill">{{ user?.wallet?.balance || 0 }}</span></a
            >
        </li> -->
        <li class="nav-item">
            <!-- <a class="nav-link" [routerLink]="['/showroom']" (click)="toggleNav()"
                ><mat-icon fontIcon="grid_view" /> Showroom</a
            > -->
            <a class="nav-link" [routerLink]="['/user-settings']" (click)="toggleNav()"
                ><mat-icon fontIcon="person" /> Profile</a
            >
        </li>
        <li>
            <a class="nav-link" [routerLink]="['/billing/credits']" (click)="toggleNav()"
                ><mat-icon fontIcon="receipt_long" /> Billing</a
            >
        </li>
        <li>
            <a class="nav-link" [routerLink]="['/security']" (click)="toggleNav()"
                ><mat-icon fontIcon="security" /> Security</a
            >
        </li>
        <!--
         <li *ngIf="user?.isEnterprise" class="nav-item mt-auto">
            <a href="#" class="nav-link" (click)="showOnboarding($event)"><mat-icon fontIcon="error" /> Tracker Info</a>
        </li>
        -->
        <li class="nav-item mt-auto">
            <a href="#" class="nav-link" (click)="logout()"><mat-icon fontIcon="logout" /> Logout</a>
        </li>
    </ul>
</div>
