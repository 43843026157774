import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ManifestService, UserService } from '@/app/services';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [RouterLink, NgIf],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
})
export class HomeComponent {
    isConfirmed: boolean = false;
    private ngUnsubscribe = new Subject<void>();

    manifest: any = {};

    constructor(
        private router: Router,
        private userService: UserService,
        private manifestService: ManifestService,
    ) {
        this.manifestService.getManifest().subscribe((manifest) => {
            this.manifest = manifest;
        });
    }

    ngOnInit() {

        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            if (user) {
                this.router.navigate(['/ideas']);
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
