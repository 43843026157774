<div class="card-body justify-content-center p-4">
    <div *ngIf="isLoading" class="p-5 my-5 d-flex justify-content-center">
        <div class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <ng-container *ngIf="!isLoading && order">
        <div class="d-flex order">
            <span class="d-block w-100">
                <span
                    class="badge rounded-pill mb-1"
                    [ngClass]="{
                        'text-bg-primary': order.attributes.status === OrderStatus.Completed.toString(),
                        'text-bg-success': order.attributes.status === OrderStatus.Paid.toString(),
                        'text-bg-warning': order.attributes.status === OrderStatus.PaymentPending.toString(),
                        'text-bg-danger': order.attributes.status === OrderStatus.PaymentFailed.toString()
                    }"
                    >{{ order.attributes.status }}</span
                >
                <strong class="d-flex justify-content-between">
                    <span class="d-flex"
                        >{{ order.attributes.item?.qty }} &times; {{ order.attributes.item?.title }}</span
                    >
                    <span class="d-flex">{{ order.attributes.price | currency: order.attributes.item?.currency }}</span>
                </strong>
                <small class="d-block opacity-50">{{ order.attributes.createdAt | date: 'medium' }}</small>
                <small class="d-block opacity-50 mt-3">{{ order.attributes.UID }}</small>
            </span>
        </div>
        <app-pay-now *ngIf="OrderStatus.PaymentFailed.toString() === order.attributes.status" [orderId]="order.id" />
        <h1 class="mt-5">Ideas</h1>
        <app-ideas-list [orderId]="order.id" [showEmptyInfo]="true" />
    </ng-container>
</div>
