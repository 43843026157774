import { Component } from '@angular/core';
import { UserService } from '@/app/services';
import { Subject, takeUntil, first } from 'rxjs';
import { User } from '@/app/models';
import { NgIf } from '@angular/common';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { VerifyCodeComponent } from '@/app/components/verify-code/verify-code.component';
import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { ChangeData } from 'ngx-intl-tel-input-gg/lib/interfaces/change-data';
import { TwoFactorAuthService } from '@/app/services/two-factor-auth.service';

@Component({
    selector: 'app-two-factor-auth-settings',
    standalone: true,
    imports: [ReactiveFormsModule, NgIf, MatSlideToggle, VerifyCodeComponent, NgxIntlTelInputModule],
    templateUrl: './two-factor-auth-settings.component.html',
    styleUrl: './two-factor-auth-settings.component.scss',
})
export class TwoFactorAuthSettingsComponent {
    private ngUnsubscribe = new Subject<void>();
    public isLoading = false;
    public user: User | null = null;
    public error = '';
    public showPhoneInput = false;
    public form = this.formBuilder.group({
        enable2FA: [false],
        phone: [''],
    });
    public changed2FA = false;
    public is2FASaved = false;

    constructor(
        private userService: UserService,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private twoFactorAuthService: TwoFactorAuthService,
    ) {}

    ngOnInit() {
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
            this.form.controls.enable2FA.setValue(user?.enable2FA || false);
            this.form.controls.phone.setValue(user?.phone || '');
            this.showPhoneInput = user?.enable2FA || false;
        });
        this.twoFactorAuthService.is2FASaved$.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: (value) => {
                this.is2FASaved = value;
                if (value) {
                    setTimeout(() => {
                        this.is2FASaved = false;
                    }, 4000);
                }
            },
        });
    }

    toggle2FA(event: any) {
        this.showPhoneInput = event.checked;
        if (!event.checked) {
            this.form.controls.phone.setValue('');
        }
        this.changed2FA = true;
    }

    submit2FA() {
        if (this.form.invalid && this.form.controls.enable2FA.value) {
            return;
        }
        const phoneData: ChangeData | null = this.form.controls.phone.value as ChangeData
        this.userService
            .updateUser({
                enable2FA: this.form.controls.enable2FA.value!,
                phone: phoneData ? phoneData.e164Number : '',
            })
            .pipe(first())
            .subscribe({
                next: (response) => {
                    if (response.codeSent) {
                        const dialogRef = this.dialog.open(VerifyCodeComponent, {
                            width: '600px',
                            disableClose: true,
                        });
                        dialogRef.componentInstance.isModal = true;
                        dialogRef.componentInstance.identifier = this.user?.email!;
                        dialogRef.componentInstance.onSuccess.pipe(first()).subscribe({
                            next: (result) => {
                                dialogRef.close();
                                this.twoFactorAuthService.is2FASavedSubject.next(result);
                            },
                        });
                        dialogRef.componentInstance.onCancel.pipe(first()).subscribe({
                            next: () => {
                                dialogRef.close();
                            },
                        });
                    } else {
                        this.twoFactorAuthService.is2FASavedSubject.next(true);
                    }
                },
                error: () => {},
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly CountryISO = CountryISO;
    protected readonly PhoneNumberFormat = PhoneNumberFormat;
    protected readonly SearchCountryField = SearchCountryField;
}
