<div class="card-body justify-content-center p-4">
    <div *ngIf="isLoading.collection" class="p-5 my-5 d-flex justify-content-center">
        <div class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <a class="back-link" [routerLink]="['/protected/companies']"
        ><img ngSrc="/assets/icons/back.svg" class="me-2" width="24" height="24" />Back to all companies</a
    >
    <ng-container *ngIf="!isLoading.collection && collection">
        <div class="d-flex text-start align-items-center justify-content-between pt-3 pb-4 mb-3">
            <h1 class="m-0">{{ collection.attributes.name }}</h1>
        </div>
        <div class="images-wrapper">
            <div class="cover-img">
                <img [src]="collection.attributes.coverImage || '/assets/cover_default.jpg'" fill priority />
            </div>
            <div class="profile-img">
                <img [src]="collection.attributes.profileImage || '/assets/profile_default.jpg'" fill priority />
            </div>
        </div>
        <app-public-ideas-list
            [showEmptyInfo]="true"
            [collectionId]="collection.id"
            [collectionSlug]="collection.attributes.slug"
        />
    </ng-container>
</div>
