export enum Endpoint {
    LOGIN = '/auth/local',
    REGISTER = '/auth/local/register',
    IPS = '/ips',
    PUBLIC_IPS = '/ips/public',
    PUBLIC_IP_COLLECTIONS = '/ips/public/collections',
    PRODUCTS = '/products',
    ORDERS = '/orders',
    ME = '/users/me',
    PRESIGNED_URL = '/generate-presigned-url',
    REMIND_PASSWORD = '/auth/forgot-password',
    RESET_PASSWORD = '/auth/reset-password',
    TRANSACTIONS = '/transactions',
    USERS = '/users',
    CONFIRMATION = '/auth/email-confirmation',
    CONFIRM_EMAIL_CHANGE = '/users/confirm-email-change',
    RESEND_CONFIRMATION = '/auth/send-email-confirmation',
    UPLOAD = '/upload',
    VERIFY_CODE = '/auth/verify-code',
    REGENERATE_VERIFICATION_CODE = '/auth/regenerate-verification-code',
}