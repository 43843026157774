import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IpService, UserService } from '@/app/services';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { Ip } from '@/app/models';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IdeaType, IpStatus, IpType } from '@/enums';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import slugify from 'slugify';
import { AutosizeModule } from 'ngx-autosize';
import { PayNowComponent } from '@/app/components/shared/pay-now/pay-now.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatFormField } from '@angular/material/form-field';
import { PdfReceiptComponent } from '../pdf-receipt/pdf-receipt.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TransferComponent } from '@/app/components/transfer/transfer.component';

@Component({
    selector: 'app-idea-details',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        MatIcon,
        MatMiniFabButton,
        MatTooltip,
        MatButton,
        AutosizeModule,
        PayNowComponent,
        MatSlideToggle,
        MatFormField,
        PdfReceiptComponent,
        RouterLink,
    ],
    templateUrl: './idea-details.component.html',
    styleUrl: './idea-details.component.scss',
})
export class IdeaDetailsComponent implements OnInit {
    private ngUnsubscribe = new Subject<void>();
    public isLoading = {
        ip: true,
        presignedUrl: false,
        update: false,
        delete: false,
    };
    public isEnterprise: boolean = false;
    public ip: Ip | null = null;
    public ipId: string | null = null;
    public editMode: boolean = false;
    public form = this.formBuilder.group({
        title: ['', [Validators.required]],
        description: [''],
        isPublic: [false],
    });
    public dialogRef: MatDialogRef<TransferComponent> | null = null;

    constructor(
        private userService: UserService,
        private router: Router,
        private ipService: IpService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
    ) {
        this.ipId = this.route.snapshot.paramMap.get('id');
    }

    setFormDefaults() {
        if (!this.ip) {
            return;
        }
        this.form.controls.title.setValue(this.ip.attributes.title);
        this.form.controls.description.setValue(this.ip.attributes.description || '');
        this.form.controls.isPublic.setValue(this.ip.attributes.isPublic || false);
    }

    loadIp() {
        if (!this.ipId) {
            return;
        }
        this.isLoading.ip = true;
        this.ipService
            .getIp(parseInt(this.ipId))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: Ip) => {
                    this.ip = response;
                    this.setFormDefaults();
                    this.isLoading.ip = false;
                },
                error: (response) => {
                    this.ip = null;
                    this.isLoading.ip = false;
                    if (response.status === 404) {
                        this.router.navigate(['/ideas']);
                    }
                },
            });
    }

    ngOnInit() {
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            if (user) {
                this.isEnterprise = user.isEnterprise ? true : false;
            }
        });

        if (!this.ipId) {
            this.router.navigate(['/ideas']);
        } else {
            this.loadIp();
        }
    }

    async downloadIp() {
        try {
            if (this.ip?.attributes.type === IpType.Text) {
                // Ensure the content exists and is of a valid type
                if (this.ip?.attributes.content) {
                    const blob = new Blob([this.ip.attributes.content], { type: 'text/plain' });
                    const anchor = document.createElement('a');
                    // Ensure the title is slugified and safe for file names
                    const fileName = `${slugify(this.ip.attributes.title).replace(/\./g, '')}.txt`;
                    anchor.download = fileName;
                    // Use safer URL object creation
                    anchor.href = URL.createObjectURL(blob);
                    anchor.click();
                    // Clean up the URL object
                    URL.revokeObjectURL(anchor.href);
                } else {
                    console.error('Content is missing or invalid.');
                }
            } else {
                this.isLoading.presignedUrl = true;
                try {
                    const ipId = parseInt(this.ipId!, 10);
                    if (isNaN(ipId)) {
                        throw new Error('Invalid IP ID.');
                    }
                    const url = await lastValueFrom(this.ipService.getPresignedUrl(ipId));
                    this.isLoading.presignedUrl = false;

                    // Safely open the URL in a new tab
                    if (url) {
                        setTimeout(() => {
                            window.open(url, '_blank');
                        }, 0);
                    } else {
                        throw new Error('URL is empty or undefined.');
                    }
                } catch (error) {
                    console.error('Failed to retrieve presigned URL:', error);
                    this.isLoading.presignedUrl = false;
                }
            }
        } catch (error) {
            console.error('An error occurred during the download process:', error);
        }
    }

    async downloadIp_archived() {
        if (this.ip?.attributes.type === IpType.Text) {
            const blob = new Blob([this.ip?.attributes.content!], { type: 'text' });
            const anchor = document.createElement('a');
            anchor.download = `${slugify(this.ip?.attributes.title).replace(/\./g, '')}.txt`;
            anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
            anchor.click();
        } else {
            this.isLoading.presignedUrl = true;
            const url = await lastValueFrom(this.ipService.getPresignedUrl(parseInt(this.ipId!)));
            this.isLoading.presignedUrl = false;

            setTimeout(() => {
                window.open(url, '_blank');
            });
        }
    }

    async downloadProtected() {
        if (this.ip?.attributes.protectedUrl === null) {
            return;
        }
        window.open(this.ip?.attributes.protectedUrl, '_blank');
    }

    async updateIp() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        const title = this.form.controls.title.value!;
        const description = this.form.controls.description.value!;
        const isPublic = this.form.controls.isPublic.value!;
        this.isLoading.update = true;
        await lastValueFrom(
            this.ipService.updateIp({
                id: parseInt(this.ipId!),
                title,
                description,
                isPublic,
            }),
        );
        this.ip!.attributes.title = title;
        this.ip!.attributes.description = description;
        this.ip!.attributes.isPublic = isPublic;
        this.isLoading.update = false;
        this.editMode = false;
    }

    closeEdit() {
        this.editMode = false;
        this.form.reset();
        this.setFormDefaults();
    }

    async deleteIp() {
        if (
            confirm(
                'Are you sure you want to delete this Idea? This operation cannot be reversed and you will not receive a refund.',
            )
        ) {
            this.isLoading.delete = true;
            await lastValueFrom(this.ipService.deleteIp(parseInt(this.ipId!)))
                .then(() => {
                    this.router.navigate(['/ideas']);
                })
                .catch((error) => {
                    this.isLoading.delete = false;
                });
        }
    }

    async transfer() {
        this.dialogRef = this.dialog.open(TransferComponent, {
            width: '600px',
            disableClose: true,
            data: { ipId: this.ipId },
        });
        this.dialogRef
            .afterClosed()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.loadIp();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly IdeaType = IdeaType;
    protected readonly IpStatus = IpStatus;
}
