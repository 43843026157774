import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { UploadIdeaButtonsComponent } from '@/app/components/shared';
import { JsonPipe, NgClass, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { AuthService, IdeaService, UserService } from '@/app/services';
import { Subject, takeUntil } from 'rxjs';
import { Idea, User } from '@/app/models';
import { EnterTextComponent } from '@/app/components/shared';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIcon } from '@angular/material/icon';
import { IdeaType } from '@/enums';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { Dialog } from '@angular/cdk/dialog';
import { PublicConfirmComponent } from '@/app/components/public-confirm/public-confirm.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OnboardingComponent } from '@/app/components/onboarding/onboarding.component';

@Component({
    selector: 'app-upload-idea',
    standalone: true,
    imports: [
        UploadIdeaButtonsComponent,
        NgOptimizedImage,
        NgIf,
        NgForOf,
        NgClass,
        RouterLink,
        EnterTextComponent,
        MatProgressBar,
        MatIcon,
        FormsModule,
        ReactiveFormsModule,
        JsonPipe,
        MatSlideToggleModule,
        AutosizeModule,
    ],
    templateUrl: './upload-idea.component.html',
    styleUrl: './upload-idea.component.scss',
})
export class UploadIdeaComponent {
    public user: User | null = null;
    public ideas: Idea[] = [];
    private ngUnsubscribe = new Subject<void>();
    public showUploadButtons = true;
    public showEnterText = false;
    public isUploading = false;
    public isPublicConfirmed = true;
    public isAnyIdeaPublic = false;
    constructor(
        private ideaService: IdeaService,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        if (!this.authService.token) {
            this.router.navigate(['/sign-up']);
        }
        this.ideaService.ideas$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((ideas: Idea[]) => {
            this.ideas = [...ideas];
            this.showUploadButtons = !this.ideas.length;
        });
        this.ideaService.isUploading$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isUploading) => {
            this.isUploading = isUploading;
        });
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
        });
    }
    toggleShowUploadButtons() {
        this.showUploadButtons = !this.showUploadButtons;
    }

    deleteIdea(idea: Idea) {
        this.ideaService.removeIdea(idea.uid);
    }

    toggleTextEnter() {
        this.showEnterText = !this.showEnterText;
    }

    checkIsPublic() {
        let publicCount = 0;
        this.ideas.forEach((idea) => {
            if (idea.isPublic) {
                publicCount++;
            }
        });
        this.isAnyIdeaPublic = !!publicCount;
    }

    nextStep() {
        if (this.authService.token) {
            this.ideaService.uploadFiles().then(() => {
                this.router.navigate(['/checkout']);
            });
        } else {
            this.router.navigate(['/sign-up']);
        }
    }

    submit() {
        this.checkIsPublic();
        if (this.isAnyIdeaPublic) {
            const dialogRef: MatDialogRef<PublicConfirmComponent> = this.dialog.open(PublicConfirmComponent, {
                disableClose: true,
                width: '600px',
            });
            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.nextStep();
                    }
                });
            return;
        } else {
            this.nextStep();
        }
    }

    updateIdea(event: Event | MatSlideToggleChange, uid: string, field: string) {
        const value = event instanceof MatSlideToggleChange ? event.checked : (event.target as HTMLInputElement).value;
        if (value === undefined || (field === 'title' && value === '')) {
            return;
        } else {
            this.ideaService.updateIdea(
                {
                    [field]: value,
                },
                uid,
            );
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly IdeaType = IdeaType;
}
